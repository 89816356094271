import { EMAIL_VERIFICATION_CLOSED, VISITS_SINCE_LOGIN_COUNT } from '@constants/application';

import * as routes from '@routes/names';

import { Notifications } from '@models/application/notifications';
import { Organization } from '@models/organization';

import * as persistence from '@utils/persistence';

export const isVendor = (organization?: Organization | null): boolean =>
  organization ? Number.isInteger(organization.vendor) : false;
export const shouldUseHorizontalMenu = (organization: Organization): boolean => isVendor(organization);

/*
 * Sort by priorities to show more important higher.
 * */
export const sortNotifications = (notifications: Notifications) => {
  return notifications;
};

export const getVisitsSinceLogin = () => Number(persistence.retrieve(VISITS_SINCE_LOGIN_COUNT)) || 1;
export const newVisitSinceLogin = () => {
  let visits = 1;

  if (persistence.has(VISITS_SINCE_LOGIN_COUNT)) {
    visits = getVisitsSinceLogin() + 1;
  }

  persistence.persist(VISITS_SINCE_LOGIN_COUNT, visits);
};
export const clearVisitsSinceLogin = () => persistence.remove(VISITS_SINCE_LOGIN_COUNT);

export const isEmailVerificationClosed = () => persistence.retrieve(EMAIL_VERIFICATION_CLOSED) === 'true';
export const setEmailVerificationClosed = (closed: boolean) => persistence.persist(EMAIL_VERIFICATION_CLOSED, closed);
export const clearEmailVerificationClosed = () => persistence.remove(EMAIL_VERIFICATION_CLOSED);

export const getNextRoute = (next: string) => {
  switch (next) {
    case 'Main':
      return routes.MAIN;
    default:
      return next;
  }
};
