import { DefaultRootState } from 'react-redux';
import { createSelector } from 'reselect';
import * as RD from '@devexperts/remote-data-ts';
import { pipe } from 'fp-ts/function';

export const alpacaAccountSelector = (state: DefaultRootState) => state.AlpacaAccount;

export const isAccountVerifiedSelector = createSelector(alpacaAccountSelector, (state) =>
  pipe(
    state,
    RD.map(({ status }) => status === 'ACTIVE'),
    RD.getOrElse(() => false),
  ),
);
