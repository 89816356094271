import styled from 'styled-components';

import { getEkoBorderRadius } from '~/ui/kit/constants/sizes';
import { Card } from '~/ui/kit/organisms/Card';

export const Container = styled(Card).attrs({ $br: 'm' })`
  background-color: var(--eko-primary-color-light-6);
  color: var(--eko-third-color);
  border-radius: ${getEkoBorderRadius};
`;

export const Title = styled.b`
  color: var(--eko-primary-color);
`;
