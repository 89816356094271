import React from 'react';
import { useSelector } from 'react-redux';

import { organizationSelector } from '@store/organization/selectors';
import * as S from './RetryAccountsLoad.styled';

type Props = {
  onRetry: VoidFunction;
};

export const RetryAccountsLoad: React.FC<Props> = (props) => {
  const org = useSelector(organizationSelector);

  return (
    <S.Container>
      Loading all your connected {org.organizationInfo?.display_name} bank accounts took longer than usual. Please{' '}
      <S.RetryButton onClick={props.onRetry}>click here</S.RetryButton> to try again.
    </S.Container>
  );
};
