import { BankAccountType } from '../types';

export const mapAccountType = (type: string = ''): BankAccountType => {
  const lowerCasedType = type.toLowerCase();
  switch (lowerCasedType) {
    case 'checking':
      return BankAccountType.CHECKING;

    case 'savings':
      return BankAccountType.SAVINGS;
  }

  return BankAccountType.CHECKING;
};
