import { pipe } from 'fp-ts/function';
import * as RD from '@devexperts/remote-data-ts';
import * as A from 'fp-ts/Array';

import { HoldingType } from '../../../Holding/Holding.entity.types';
import { usePortfolioHoldings } from '../usePortfolioHoldings';
import { useMemo } from 'react';

export const useHoldingsByType = (type: HoldingType) => {
  const [portfolioHoldings] = usePortfolioHoldings();

  return useMemo(
    () => pipe(portfolioHoldings, RD.map(A.filter((holding) => holding.type === type))),
    [portfolioHoldings, type],
  );
};
