import React from 'react';

import { formatMoney, formatQuantity } from '@utils/money';
import { LoadableComponentProps } from '@utils/LoadableComponentProps';
import { Skeleton } from '~/ui/kit/atoms/Skeleton';

import * as S from './HoldingShares.styled';

type Props = {
  className?: string;
  lastElementLabel: React.ReactNode;
} & LoadableComponentProps<{
  shares: number;
  sharesValue: number;
  lastElementValue: React.ReactNode;
}>;

const Template: React.FC<{
  shares: React.ReactNode;
  sharesValue: React.ReactNode;
  lastElementValue: React.ReactNode;
  lastElementLabel: React.ReactNode;
}> = ({ shares, sharesValue, lastElementValue, lastElementLabel }) => (
  <>
    <S.Item before="Shares owned">{shares}</S.Item>
    <S.Item before="Value of shares">{sharesValue}</S.Item>
    <S.Item before={lastElementLabel}>{lastElementValue}</S.Item>
  </>
);

export const HoldingShares: React.FC<Props> = (props) => {
  const templateProps = props.isLoading
    ? {
        shares: <Skeleton.LineSkeleton />,
        sharesValue: <Skeleton.LineSkeleton />,
        lastElementLabel: props.lastElementLabel,
        lastElementValue: <Skeleton.LineSkeleton />,
      }
    : {
        shares: <>{formatQuantity(props.shares)}</>,
        sharesValue: <>{formatMoney(props.sharesValue)}</>,
        lastElementLabel: props.lastElementLabel,
        lastElementValue: props.lastElementValue,
      };

  return (
    <S.Container className={props.className}>
      <Template {...templateProps} />
    </S.Container>
  );
};
