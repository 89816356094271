import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as F from 'fp-ts/function';
import * as RD from '@devexperts/remote-data-ts';

import { altOnError, sequenceT } from '@utils/RemoteData';
import { isExternalAccountsFactory } from '~/entities/ach';

import { ACHAccountsValue } from '../../api';
import { achAccountsSelector } from './achAccountsSelector';
import { achSelector } from './activeACH.selector';

export const useIsActiveAccountExternalSelector = () => {
  const achRD = useSelector(achSelector);
  const accountsPlain = useSelector(achAccountsSelector);

  const accounts = useMemo(
    () =>
      F.pipe(
        accountsPlain,
        altOnError((): ACHAccountsValue => RD.success([])),
      ),
    [accountsPlain],
  );

  const allowedAch = useMemo(
    () =>
      F.pipe(
        sequenceT(achRD, accounts),
        RD.map(([currentAch, integratedAccounts]) => isExternalAccountsFactory(integratedAccounts)(currentAch)),
      ),
    [achRD, accounts],
  );

  return F.pipe(
    allowedAch,
    RD.getOrElse(() => false),
  );
};
