import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as F from 'fp-ts/function';
import * as RD from '@devexperts/remote-data-ts';
import * as O from 'fp-ts/Option';

import {
  achAccountsSelector,
  achSelector,
  editACHSelector,
  useIsActiveAccountExternalSelector,
} from '../api/selectors';
import { loadACHAccountsAction } from '../api/stores';
import { checkIfEditAccount } from '../helpers';
import { ChangeAccountAssert } from '../ui/ChangeAccountAssert';
import { RetryAccountsLoad } from '../ui/RetryAccountsLoad';

type Params = {
  lockReason?: string;
  isManualFallback?: boolean;
  disabledExternalAccountsMessage?: React.ReactNode;
  disableExternalAccounts?: boolean;
  portfolioId: string;
};

export const useACHInputDescription = ({
  lockReason,
  isManualFallback = false,
  disabledExternalAccountsMessage = null,
  disableExternalAccounts = false,
  portfolioId,
}: Params) => {
  const dispatch = useDispatch();
  const achRD = useSelector(achSelector);
  const editACH = useSelector(editACHSelector);
  const accountsRD = useSelector(achAccountsSelector);
  const isActiveAccountExternal = useIsActiveAccountExternalSelector();

  const reloadAccounts = useCallback(() => {
    if (portfolioId) {
      dispatch(loadACHAccountsAction(portfolioId));
    }
  }, [dispatch, portfolioId]);

  const accountsFailureDescription = useMemo(
    () =>
      F.pipe(
        accountsRD,
        RD.fold(
          () => null,
          () => null,
          () => <RetryAccountsLoad onRetry={reloadAccounts} />,
          () => null,
        ),
      ),
    [accountsRD, reloadAccounts],
  );

  const assertMessage = useMemo(
    () => (
      <ChangeAccountAssert>
        {disableExternalAccounts && isActiveAccountExternal ? disabledExternalAccountsMessage : null}
      </ChangeAccountAssert>
    ),
    [disableExternalAccounts, isActiveAccountExternal, disabledExternalAccountsMessage],
  );

  return useMemo(
    () =>
      F.pipe(
        achRD,
        RD.toOption,
        O.chain((bankAccount) => F.pipe(editACH, O.map(checkIfEditAccount(bankAccount)))),
        O.map((areAccountsEqual) => (areAccountsEqual ? undefined : assertMessage)),
        O.getOrElse(() => {
          if (lockReason) {
            return <>{lockReason}</>;
          }

          if (RD.isSuccess(achRD) && accountsFailureDescription !== null) {
            return <>{accountsFailureDescription}</>;
          }

          if (RD.isSuccess(achRD) && isManualFallback) {
            return assertMessage;
          }
        }),
      ),
    [achRD, editACH, lockReason, isManualFallback, accountsFailureDescription, assertMessage],
  );
};
