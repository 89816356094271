import {
  CreatePortfolioParams,
  CreatePortfolioDTORequest,
  PortfolioTypeDtoValue,
  PortfolioTypeValue,
  PORTFOLIO_TYPE,
  PORTFOLIO_TYPE_DTO,
} from '../portfolio.types';

export const mapPortfolioTypeToDTO = (type: PortfolioTypeValue): PortfolioTypeDtoValue => {
  switch (type) {
    case PORTFOLIO_TYPE.PRE_MADE:
      return PORTFOLIO_TYPE_DTO.GUIDED;
    case PORTFOLIO_TYPE.MIXED:
      return PORTFOLIO_TYPE_DTO.MIXED;
    case PORTFOLIO_TYPE.SELF_DIRECT:
      return PORTFOLIO_TYPE_DTO.SELF_DIRECT;
  }
};

export const mapPortfolioRequestToDTO = (params: CreatePortfolioParams): CreatePortfolioDTORequest => ({
  name: params.name,
  portfolio_type: mapPortfolioTypeToDTO(params.portfolioType),
  risk_level: params.risk || 0,
  prebuilt_id: params.premadeId || '',
  topics: params.topics || [],
  investment_amount: params.amount,
  investment_reason: params.reason,
});
