import * as RD from '@devexperts/remote-data-ts';
import { constVoid } from 'fp-ts/function';

import { mapApiError } from '@models/ApiError';
import { response } from '@api/helpers';
import { privateAPIv2 } from '~/api/base';

import { mapPortfolioItemDto } from '../list';
import { PortfolioItemDTO, PortfolioListItemData, PortfolioListItem } from '../portfolio.types';

export const getPortfolioById = (
  portfolioId: string,
  onSuccess: (newPortfolioItem: PortfolioListItem) => void = constVoid,
): Promise<PortfolioListItemData> => {
  return response(privateAPIv2.get<PortfolioItemDTO>(`/portfolios/${portfolioId}/`))
    .then((data) => {
      const mapped = mapPortfolioItemDto(data);
      onSuccess(mapped);
      return mapped;
    })
    .then(RD.success)
    .catch((e) => RD.failure(mapApiError(e)));
};
