import * as builder from '../builder';
import Types from '@store/organization/types';

import { Organization } from '@models/organization';

export type StartedAction = {
  type: Types.FETCH_ORGANIZATION_STARTED;
  payload: {
    isLoading: boolean;
    error: string;
  };
};

export type SucceededAction = {
  type: Types.FETCH_ORGANIZATION_SUCCEEDED;
  payload: {
    organizationInfo: Organization;
    isLoading: boolean;
    error: string;
  };
};

export type FailedAction = {
  type: Types.FETCH_ORGANIZATION_FAILED;
  payload: {
    isLoading: boolean;
    error: string;
  };
};

export type Action = StartedAction | SucceededAction | FailedAction;

export const fetchOrganization = builder.buildRequestAction(Types.FETCH_ORGANIZATION);

export const fetchOrganizationStarted = (): StartedAction => ({
  type: Types.FETCH_ORGANIZATION_STARTED,
  payload: { isLoading: true, error: '' },
});

export const fetchOrganizationSucceeded = (organization: Organization): SucceededAction => {
  return {
    type: Types.FETCH_ORGANIZATION_SUCCEEDED,
    payload: {
      organizationInfo: organization.allowed_portfolio_types
        ? organization
        : Object.assign(organization, { allowed_portfolio_types: ['MIXED', 'SELF_DIRECT', 'GUIDED'] }),
      isLoading: false,
      error: '',
    },
  };
};

export const fetchOrganizationFailed = (error: string): FailedAction => ({
  type: Types.FETCH_ORGANIZATION_FAILED,
  payload: {
    isLoading: false,
    error,
  },
});
