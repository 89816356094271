import { useCallback, useState } from 'react';
import * as RD from '@devexperts/remote-data-ts';

import { AssetData } from '../../asset.types';
import { getAsset } from '../../api';

export const useLoadAsset = () => {
  const [data, setData] = useState<AssetData>(RD.initial);

  const loadData = useCallback((ticker: string) => {
    if (ticker) {
      setData(RD.pending);

      getAsset(ticker).then(setData);
    }
  }, []);

  return [data, loadData] as const;
};
