import * as builder from '../builder';
import BrokerAccountTypes from '@store/broker_account/types';

import { BrokerAccount } from '@models/broker';

export const fetchBrokerAccount = builder.buildRequestAction(BrokerAccountTypes.FETCH_BROKER_ACCOUNT_REQUESTED);
export const fetchBrokerAccountStarted = builder.buildStartedAction(BrokerAccountTypes.FETCH_BROKER_ACCOUNT_STARTED);
export const fetchBrokerAccountSucceeded = builder.buildSucceededAction<BrokerAccount>(
  BrokerAccountTypes.FETCH_BROKER_ACCOUNT_SUCCEEDED,
  'accountInfo',
);
export const fetchBrokerAccountFailed = builder.buildFailedAction(BrokerAccountTypes.FETCH_BROKER_ACCOUNT_FAILED);
