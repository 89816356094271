import * as RD from '@devexperts/remote-data-ts';
import { DefaultRootState } from 'react-redux';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import * as builder from '@store/builder';

import { HoldingsEntityData } from '../Holding.entity.types';
import { getHoldingsByPortfolio } from '../api';
import { holdingsSelector } from '../selectors';

const initialState: HoldingsEntityData = RD.initial;

export const actions = builder.getModuleActions('Holdings');

export const setAction = (payload: typeof initialState) => {
  return {
    type: actions.SET,
    payload,
  };
};

type ActivePortfolioHoldingsAction = ReturnType<typeof setAction>;

export const HoldingsReducer = (state = initialState, action: ActivePortfolioHoldingsAction) => {
  switch (action.type) {
    case actions.SET: {
      return action.payload;
    }
  }

  return state;
};

export const loadAction = (portfolioId: string, keepCache?: boolean) => ({
  type: actions.FETCH,
  payload: { portfolioId, keepCache },
});

export function* loadPortfolioListSaga(action: ReturnType<typeof loadAction>) {
  const state: DefaultRootState = yield select();

  const hasHoldingsPrevInfo = RD.isSuccess(holdingsSelector(state));

  if (!hasHoldingsPrevInfo || !action.payload.keepCache) {
    yield put(setAction(RD.pending));
  }

  const mappedResult: HoldingsEntityData = yield call(getHoldingsByPortfolio(action.payload.portfolioId));
  yield put(setAction(mappedResult));
}

export function* watchHoldingsSaga() {
  yield takeLatest(actions.FETCH, loadPortfolioListSaga);
}
