import * as RD from '@devexperts/remote-data-ts';

import { response } from '@api/helpers';
import { privateAPIv2 } from '~/api/base';
import { ApiError, mapApiError } from '@models/ApiError';

import { BankAccount } from '../types';

export type DisconnectBankAccountResponse = RD.RemoteData<ApiError, BankAccount>;

export const disconnectBankAccount = (_portfolioId: string, bankAccount: BankAccount) => {
  return response<DisconnectBankAccountResponse>(privateAPIv2.delete('/bank-accounts/'))
    .then(() => bankAccount)
    .then(RD.success)
    .catch((e) => RD.failure(mapApiError(e)));
};
