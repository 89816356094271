import * as types from './types';
import * as RD from '@devexperts/remote-data-ts';
import { isPayloadSafetyType } from '@store/util';

import { Id } from '@models/common';
import { Reason, Appetite, Step, Steps } from '@models/onboarding';
import { OnboardingAction } from '@store/onboarding/actions';
import { PortfolioTypeValue } from '~/entities/protfolio';
import { PremadePortfoliosData } from '~/entities/protfolio/preMade';

export interface OnboardingState {
  premadeType?: string;
  portfolioType?: PortfolioTypeValue;
  legalName: string;
  email: string;
  investmentAmount: number;
  appetite?: Appetite;
  reason?: Reason;
  risk: number;
  recommendedRisk: number;
  stepsValid: { name: string; isValid: boolean }[];
  topics: Id[];
  onboardingStep: Step;
  premade: PremadePortfoliosData;
}

const initialState: OnboardingState = {
  portfolioType: undefined,
  premadeType: undefined,
  legalName: '',
  email: '',
  investmentAmount: 100,
  risk: 0,
  recommendedRisk: 0,
  stepsValid: [],
  topics: [],
  onboardingStep: Steps.RISKS,
  premade: RD.initial,
};

export default function onboardingReducer(state = initialState, action: OnboardingAction): OnboardingState {
  if (action.type === types.PERSISTENCE_ONBOARDING_TYPES.CLEAR_ONBOARDING) {
    return {
      ...initialState,
      premade: state.premade,
    };
  }

  if (isPayloadSafetyType(action.type, types.PERSISTENCE_ONBOARDING_TYPES)) {
    return { ...state, ...action.payload };
  }

  return { ...state };
}
