import { ConfirmationTypes } from '@store/alpaca_onboarding/types';
import { isPayloadSafetyType } from '@store/util';
import { ConfirmationSucceededAction } from './actions';

export interface OnboardingAlpacaState {
  confirmation: {
    isLoading: boolean;
    completed: boolean;
    error: string;
  };
}

const initialData: OnboardingAlpacaState = {
  confirmation: {
    completed: false,
    isLoading: false,
    error: '',
  },
};

export default function onboardingAlpacaReducer(
  state = initialData,
  action: ConfirmationSucceededAction,
): OnboardingAlpacaState {
  if (action.type === ConfirmationTypes.RESET_ERROR) {
    const { error: _, ...confirmation } = state.confirmation;
    return { confirmation: { ...confirmation, error: '' } };
  }

  if (isPayloadSafetyType(action.type, ConfirmationTypes)) {
    const confirmation = { completed: false, ...action.payload };

    confirmation.completed = action.type === ConfirmationTypes.SUCCEEDED;

    return { ...state, confirmation: { ...confirmation } };
  }

  return state;
}
