import * as RD from '@devexperts/remote-data-ts';

import { response } from '@api/helpers';
import { privateAPIv2 } from '~/api/base';
import { mapApiError } from '@models/ApiError';

import { mapBankAccountDto, mapSetAchPayload } from '../helpers';
import { SetBankAccountParams } from './types';
import { BankAccountDTO } from '../types';

const mapProviderType = (params: SetBankAccountParams['payload']) => {
  switch (params.type) {
    case 'manual':
    case 'platform':
    case 'plaid': {
      return params.type;
    }
    case 'saved-manual': {
      return 'manual';
    }
    case 'saved-plaid': {
      return 'plaid';
    }
  }
};

const mapRequestParamsToDTO = (params: SetBankAccountParams['payload']) => ({
  provider: mapProviderType(params),
  payload: mapSetAchPayload(params),
});

export const setBankAccount = (portfolioId: string, params: SetBankAccountParams['payload']) => {
  return response(
    privateAPIv2.post<BankAccountDTO>(
      `/portfolios/${portfolioId}/bank-account/connect/`,
      mapRequestParamsToDTO(params),
    ),
  )
    .then(mapBankAccountDto)
    .then(RD.success)
    .catch((e) => RD.failure(mapApiError(e)));
};
