import { DefaultRootState } from 'react-redux';
import { createSelector } from 'reselect';

import { profileSelector } from '@store/profile/selectors';
import { ProfileState } from '@store/profile';

export const selectIsAuthenticated = createSelector(
  profileSelector,
  (Profile: ProfileState): boolean => !!Profile.profileInfo,
);

const loginSelector = (state: DefaultRootState) => state.Authentication.login;

export const selectIsLoginLoading = createSelector(loginSelector, (Login) => Login.isLoading);

export const selectLoginError = createSelector(loginSelector, (Login) => Login.error);
