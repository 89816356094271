import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Organization } from '@models/organization';

import { newVisitSinceLogin, shouldUseHorizontalMenu } from '@services/application';

import { setMenuType } from '@store/application/actions';
import { selectProfile } from '@store/profile/selectors';

export const useCustomization = (organization: Organization | null) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (organization) {
      dispatch(setMenuType(shouldUseHorizontalMenu(organization) ? 'horizontal' : 'vertical'));
    }
  }, [organization, dispatch]);
};

export const useVisits = () => {
  const profile = useSelector(selectProfile);

  useEffect(
    () => {
      if (profile) {
        newVisitSinceLogin();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
};
