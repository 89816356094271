import { useMemo } from 'react';
import { pipe } from 'fp-ts/function';
import * as RD from '@devexperts/remote-data-ts';
import * as A from 'fp-ts/Array';

import { fromOption } from '@utils/RemoteData';
import { ApiError } from '@models/ApiError';

import { usePortfolioHoldings } from '../usePortfolioHoldings';

export const useHoldingById = (ticker: string) => {
  const [data] = usePortfolioHoldings();

  return useMemo(
    () =>
      pipe(
        data,
        RD.map(A.findFirst(({ ticker: holdingTicker }) => holdingTicker === ticker)),
        RD.chain(
          fromOption(() =>
            RD.failure({
              code: 404,
            } as ApiError),
          ),
        ),
      ),
    [data, ticker],
  );
};
