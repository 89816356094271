export enum Reason {
  MORE_OUT_OF_SAVINGS = 'MORE_OUT_OF_SAVINGS',
  BUILD_EXTRA_INCOME = 'BUILD_EXTRA_INCOME',
  SAVE_EXTRA_FOR_UNIVERSITY = 'SAVE_EXTRA_FOR_UNIVERSITY',
  SAVE_EXTRA_FOR_EMERGENCY = 'SAVE_EXTRA_FOR_EMERGENCY',
  DONT_KNOW = 'DONT_KNOW',
}

export enum Steps {
  RISKS = 'RISKS',
  TOPICS = 'TOPICS',
}

export const appetitePointsMap = {
  minimize: 2,
  balance: 4,
  maximize: 6,
};

export type Appetite = keyof typeof appetitePointsMap;

export type Step = keyof typeof Steps;
