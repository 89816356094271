import { useCallback, useState } from 'react';
import * as RD from '@devexperts/remote-data-ts';

import { AddHoldingParams, addHoldingApi, AddHoldingResult } from '../../api';

type Params = {
  onSuccess?: VoidFunction;
};

export const useAddHolding = ({ onSuccess }: Params = {}) => {
  const [state, setState] = useState<AddHoldingResult>(RD.initial);

  const addHolding = useCallback(
    (params: AddHoldingParams) => {
      setState(RD.pending);
      addHoldingApi(params, onSuccess).then(setState);
    },
    [onSuccess],
  );

  const cleanUp = useCallback(() => {
    setState(RD.initial);
  }, []);

  return { state, addHolding, cleanUp };
};
