import addSeconds from 'date-fns/addSeconds';

import { mapNumberDto } from '~/shared/dtoMappers';

import { MarketInfo, MarketInfoDTO } from '../market.types';

export const mapMarketInfoDtoToDomainHelper = (dto: MarketInfoDTO): MarketInfo => {
  const now = new Date();
  const secondsToOpen = mapNumberDto(dto.time_to_open);
  const secondsToClose = mapNumberDto(dto.time_to_close);

  return {
    isOpen: Boolean(dto.is_open),
    closeDateTime: addSeconds(now, secondsToClose),
    openDateTime: addSeconds(now, secondsToOpen),
  };
};
