import EmotIcon from '@assets/images/emoticon.png';

import { useThemeContext } from '~/contexts/app';

import { Popup } from '~/ui/kit/organisms/Popup';

const CONFIRMATION_TITLE = 'Deleting your bank account will cancel all pending transfers';

const CONFIRMATION_SUB_TEXT =
  'If you delete this account, any incoming or outgoing transfers will be ' +
  'cancelled immediately. To deposit or withdraw money you would need to ' +
  'reconnect this account or add another.';

interface Props {
  visible: boolean;
  onAccept: () => void;
  onCancel: () => void;
}

export const DeleteACHConfirmationDialog = ({ visible, onAccept, onCancel }: Props) => {
  const { showEmoji } = useThemeContext();

  return (
    <Popup
      title={
        <>
          {CONFIRMATION_TITLE}
          {showEmoji && <img src={EmotIcon} alt="" />}
        </>
      }
      subText={CONFIRMATION_SUB_TEXT}
      yesBtnText="Delete account"
      noBtnText="Cancel"
      isVisible={visible}
      onClickYes={onAccept}
      onClickNo={onCancel}
      isLoading={false}
    />
  );
};
