import * as RD from '@devexperts/remote-data-ts';
import { constVoid } from 'fp-ts/function';

import { response } from '@api/helpers';
import { mapApiError } from '@models/ApiError';
import { privateAPIv2 } from '~/api/base';

import { PortfolioItemDTO, PortfolioListItem, PortfolioRiskLevelValue } from '../portfolio.types';
import { mapPortfolioItemDto } from '../list';

export type EditPortfolioPropertyParams = {
  portfolioId: string;
} & (
  | {
      key: 'risk';
      value: PortfolioRiskLevelValue;
    }
  | {
      key: 'name';
      value: string;
    }
);
export const editPortfolioProperty = (
  params: EditPortfolioPropertyParams,
  onSuccess: (portfolio: PortfolioListItem) => void = constVoid,
) => {
  const endpoint = params.key === 'risk' ? 'risk-level' : 'name';

  const data = params.key === 'risk' ? { risk_level: params.value } : { name: params.value };

  return response<PortfolioItemDTO>(privateAPIv2.put(`/portfolios/${params.portfolioId}/${endpoint}/`, data))
    .then((res) => {
      const mappedRes = mapPortfolioItemDto(res);
      onSuccess(mappedRes);

      return mappedRes;
    })
    .then(RD.success)
    .catch((e) => RD.failure(mapApiError(e)));
};
