import { TopicDto, Topic, TopicCategory, TOPIC_CATEGORY_TYPE } from '../../topic.types';

const mapTopicCategory = (dto?: string): TopicCategory => {
  switch (dto) {
    case 'industry':
      return TOPIC_CATEGORY_TYPE.INDUSTRY;
    case 'environmental':
      return TOPIC_CATEGORY_TYPE.ENVIRONMENTAL;
    case 'social':
      return TOPIC_CATEGORY_TYPE.SOCIAL;
  }

  return TOPIC_CATEGORY_TYPE.INDUSTRY;
};

export const mapTopicDtoToDomain = (dto: TopicDto): Topic => ({
  id: dto.id,
  category: mapTopicCategory(dto.category),
  description: dto.description,
  detailedDescription: dto.detailed_description,
  icon: dto.icon,
  name: dto.name,
});

export const mapTopicsDtoToDomain = (dto: TopicDto[]): Topic[] => dto.map(mapTopicDtoToDomain);
