import alpacaOnboardingSaga from '@store/alpaca_onboarding/sagas';
import { AxiosError } from 'axios';
import { all, call, put, spawn, takeLatest } from 'redux-saga/effects';

import * as appActions from '@store/application/actions';
import { profileSaga } from '@store/profile/sagas';
import { organizationSaga } from '@store/organization/sagas';
import AppTypes from '@store/application/types';

import { HTTP_STATUSES } from '@api/constants';
import * as authUtils from '~/authentication/auth';

import { loadProfile } from '@services/profile';
import { loadOrganization } from '@services/organization';
import { brokerAccountSaga } from '@store/broker_account/sagas';
import { sagas as achSagas } from '~/entities/ach';
import { sagas as alpacaAccountSagas } from '@store/alpaca_account';
import { brokerageAccountSagas } from '~/entities/brokerageAccount/api/stores';
import { watchTransfersSaga } from '~/entities/transfers';
import {
  loadProfileSettingAction,
  profileSettingsSaga,
  userSettingsSaga,
  loadUserSettingAction,
} from '@store/settings';
import { topicsSagas } from '~/entities/topic';
import { watchHoldingsSaga } from '~/entities/Holding';
import { portfolioListSagas } from '~/entities/protfolio/list';

const isUserWithoutProfile = (status: number) => status === HTTP_STATUSES.NOT_FOUND;

function* loadError(e: AxiosError) {
  if (isUserWithoutProfile(e.response?.status || 0)) {
    yield put(appActions.setIsExternal(true));
  }
}

function* initQueryAuth(accessToken: string, refreshToken: string, successCallback: () => void) {
  authUtils.saveAccessToken(accessToken);
  authUtils.saveRefreshToken(refreshToken);
  yield call(loadProfile);
  successCallback();
}

function* loadApp({ payload }: appActions.LoadAppAction) {
  try {
    yield put(appActions.loadingAppStarted());

    if (payload?.tokens) {
      yield call(() =>
        initQueryAuth(payload.tokens?.accessToken || '', payload.tokens?.refreshToken || '', payload.successCallback),
      );
    } else {
      yield call(loadProfile);
    }

    yield all([call(loadOrganization)]);

    yield put(loadProfileSettingAction());
    yield put(loadUserSettingAction());

    yield put(appActions.loadingAppSucceeded());
  } catch (e) {
    yield call(loadError, e as unknown as AxiosError);
    const errorMessage = (e as unknown as AxiosError).message || 'Log in failed';
    yield put(appActions.loadingAppFailed(errorMessage));
  }
}

function* applicationSaga() {
  yield takeLatest(AppTypes.LOAD, loadApp);
  yield spawn(profileSaga);
  yield spawn(organizationSaga);
  yield spawn(brokerAccountSaga);
  yield spawn(alpacaOnboardingSaga);
  yield spawn(achSagas);
  yield spawn(brokerageAccountSagas);
  yield spawn(alpacaAccountSagas);
  yield spawn(watchTransfersSaga);
  yield spawn(profileSettingsSaga);
  yield spawn(userSettingsSaga);

  // new API
  yield spawn(portfolioListSagas);
  yield spawn(topicsSagas);
  yield spawn(watchHoldingsSaga);
}

export default applicationSaga;
