import * as RD from '@devexperts/remote-data-ts';
import { ApiError } from '@models/ApiError';

export interface TopicDto {
  id: number;
  category: 'environmental' | 'industry' | 'social';
  description: string;
  detailed_description: string;
  icon: string;
  name: string;
}

export const TOPIC_CATEGORY_TYPE = {
  INDUSTRY: 'industry',
  SOCIAL: 'social',
  ENVIRONMENTAL: 'environmental',
} as const;

type TopicCategoryTypeKey = keyof typeof TOPIC_CATEGORY_TYPE;
export type TopicCategory = (typeof TOPIC_CATEGORY_TYPE)[TopicCategoryTypeKey];

export type Topic = {
  id: number;
  category: TopicCategory;
  description: string;
  detailedDescription: string;
  icon: string;
  name: string;
};

export type TopicsData = RD.RemoteData<ApiError, Topic[]>;
