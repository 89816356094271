import * as RD from '@devexperts/remote-data-ts';
import { DefaultRootState } from 'react-redux';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';

import * as builder from '@store/builder';
import { mapApiError } from '@models/ApiError';

import { getPortfolioList } from '../api';
import { portfolioListSelector } from '../selectors';
import { PortfolioList, PortfolioListPlain } from '../portfolioList.types';
import { PortfolioListItem } from '../../portfolio.types';

const initialState: PortfolioList = RD.initial;

export const actions = builder.getModuleActions('PortfolioList');

export const setAction = (payload: PortfolioList) => ({
  type: actions.SET,
  payload,
});

export const addPortfolioListItem = (payload: PortfolioListPlain) => ({
  type: actions.ADD,
  payload,
});

export const updatePortfolioItem = (portfolioItem: Partial<PortfolioListItem>) => ({
  type: actions.UPDATE,
  payload: portfolioItem,
});

export const deletePortfolioItem = (id: string) => ({
  type: actions.DELETE,
  payload: id,
});

type PortfolioListAction =
  | ReturnType<typeof setAction>
  | ReturnType<typeof addPortfolioListItem>
  | ReturnType<typeof updatePortfolioItem>
  | ReturnType<typeof deletePortfolioItem>;

export const PortfolioListReducer = (state = initialState, action: PortfolioListAction) => {
  switch (action.type) {
    case actions.SET: {
      return action.payload as PortfolioList;
    }
    case actions.ADD: {
      return pipe(
        state,
        RD.map((portfolioList) => [...portfolioList, ...(action.payload as PortfolioListPlain)]),
      );
    }
    case actions.UPDATE: {
      const payload = action.payload as Partial<PortfolioListItem>;
      return pipe(
        state,
        RD.map(A.map((listItem) => (listItem.id === payload.id ? { ...listItem, ...payload } : listItem))),
      );
    }
    case actions.DELETE: {
      const id = action.payload as string;
      return pipe(state, RD.map(A.filter((portfolioList) => portfolioList.id !== id)));
    }
  }

  return state;
};

export const loadAction = builder.buildRequestAction(actions.FETCH);

export function* loadPortfolioListSaga() {
  const state: DefaultRootState = yield select();

  const hasPortfolioPrevInfo = RD.isSuccess(portfolioListSelector(state));

  if (!hasPortfolioPrevInfo) {
    yield put(setAction(RD.pending));
  }

  try {
    const mappedResult: PortfolioListPlain = yield call(getPortfolioList());
    yield put(setAction(RD.success(mappedResult)));
  } catch (e) {
    yield put(setAction(RD.failure(mapApiError(e))));
  }
}

export function* watchLoadPortfolioListSaga() {
  yield takeLatest(actions.FETCH, loadPortfolioListSaga);
}
