import api, { privateAPI, privateAPIv2 } from '~/api/base';
import {
  CreateProfileRequestBody,
  ProfileSettingsDTO,
  ProfileSettingsValue,
  ChangePasswordBaseParams,
  ResetPasswordComplete,
  ResetPasswordRequestParams,
} from '../schemas/profile';

const profileApi = {
  retrieve: () => privateAPI.get('/profile/'),

  createProfile: async (profileData: CreateProfileRequestBody) => privateAPI.post('/profile/create/', profileData),

  // TODO move out to portfolio
  changeRiskLevel: async (portfolioId: string, riskLevel: number) =>
    privateAPIv2.put(`/portfolios/${portfolioId}/risk-level/`, {
      risk_level: riskLevel,
    }),

  getProfileMetaInfo: () => privateAPI.get<ProfileSettingsValue>('/profile/meta/'),

  setProfileMetaInfo: (newValue: ProfileSettingsDTO) =>
    privateAPI.patch<ProfileSettingsValue>('/profile/meta/', newValue),

  resetPassword: (data: ResetPasswordRequestParams) => api.post('/password-reset/', data),

  resetPasswordComplete: (data: ResetPasswordComplete) => api.post('/password-reset-complete/', data),

  resetPasswordVerify: (data: ChangePasswordBaseParams) => api.post('/password-reset-verify/', data),
};

export default profileApi;
