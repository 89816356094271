import { spawn } from 'redux-saga/effects';

import { watchLoadBrokerageSaga, BrokerageAccount, loadAction, updateAction } from './brokerageAccount';

function* sagas() {
  yield spawn(watchLoadBrokerageSaga);
}

export {
  loadAction as loadBrokerageAccount,
  updateAction as updateBrokerageAccount,
  watchLoadBrokerageSaga,
  BrokerageAccount,
  sagas as brokerageAccountSagas,
};
