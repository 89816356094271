import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';
import * as RD from '@devexperts/remote-data-ts';

import { PortfolioListItem } from '../../portfolio.types';
import { updatePortfolioItem, usePortfolioList } from '../../list';

export const useChangeLocalPortfolioValue = () => {
  const dispatch = useDispatch();
  const portfolioList = usePortfolioList();

  return useCallback(
    (
      portfolioId: string,
      getNextStateOrPartialNextState:
        | Partial<PortfolioListItem>
        | ((previousState: PortfolioListItem) => Partial<PortfolioListItem>),
    ) => {
      if (portfolioId) {
        if (typeof getNextStateOrPartialNextState === 'function') {
          pipe(
            portfolioList,
            RD.map(A.findFirst(({ id }) => id === portfolioId)),
            RD.toOption,
            O.flatten,
            O.map(getNextStateOrPartialNextState),
            O.map((updatedPortfolioState) =>
              dispatch(updatePortfolioItem({ id: portfolioId, ...updatedPortfolioState })),
            ),
          );
        } else {
          dispatch(updatePortfolioItem({ id: portfolioId, ...getNextStateOrPartialNextState }));
        }
      }
    },
    [dispatch, portfolioList],
  );
};
