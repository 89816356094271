import { useCallback, useState } from 'react';
import * as RD from '@devexperts/remote-data-ts';

import { getConnectedBankAccounts } from '../api/getConnectedBankAccounts.api';
import { BankAccount, ConnectedBankAccountsData } from '../types';

export const useConnectedBankAccounts = (onSuccess?: (accounts: BankAccount[]) => void) => {
  const [state, setState] = useState<ConnectedBankAccountsData>(RD.initial);

  const load = useCallback(() => {
    setState(RD.pending);
    getConnectedBankAccounts(onSuccess).then(setState);
  }, [onSuccess]);

  return [state, load] as const;
};
