enum AuthTypes {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
}

export enum LoginTypes {
  STARTED = 'LOGIN_STARTED',
  RESET = 'LOGIN_RESET',
  SUCCEEDED = 'LOGIN_SUCCEEDED',
  FAILED = 'LOGIN_FAILED',
}

export default AuthTypes;
