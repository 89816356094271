import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { portfolioItemEq } from '../../helpers';
import { loadPortfolioList } from '../../list/store';
import { selectActivePortfolioInfo } from '../../selectors';

export const useActivePortfolioInfo = () => {
  const dispatch = useDispatch();

  const reloadPortfolios = useCallback(() => {
    dispatch(loadPortfolioList());
  }, [dispatch]);

  const activePortfolio = useSelector(selectActivePortfolioInfo, portfolioItemEq.equals);

  return [activePortfolio, reloadPortfolios] as const;
};
