import * as RD from '@devexperts/remote-data-ts';
import { constVoid } from 'fp-ts/function';

import { ApiError, mapApiError } from '@models/ApiError';
import { privateAPIv2 } from '~/api/base';
import { response } from '@api/helpers';

export type AddHoldingResult = RD.RemoteData<ApiError, null>;

export type AddHoldingParams = {
  ticker: string;
  portfolioId: string;
};

export const addHoldingApi = (params: AddHoldingParams, onSuccess = constVoid) => {
  return response(privateAPIv2.post(`/portfolios/${params.portfolioId}/holdings/`, { symbol: params.ticker }))
    .then((): AddHoldingResult => RD.success(null))
    .then((data) => {
      onSuccess();
      return data;
    })
    .catch((e): AddHoldingResult => RD.failure(mapApiError(e)));
};
