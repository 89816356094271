import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as RD from '@devexperts/remote-data-ts';
import { pipe } from 'fp-ts/function';

import { achSelector } from '../../api';

export const useConnectedAchInfo = () => {
  const activeACHAccount = useSelector(achSelector);

  const plainId = useMemo(
    () =>
      pipe(
        activeACHAccount,
        RD.map(({ id }) => id),
        RD.getOrElse(() => ''),
      ),
    [activeACHAccount],
  );

  return [activeACHAccount, plainId] as const;
};
