import { useMemo } from 'react';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import * as RD from '@devexperts/remote-data-ts';
import { checkById } from '@utils/compare';

import { usePortfolioList } from '../usePortfolioList';

export const usePortfolioInfoById = (portfolioId: string = '') => {
  const allPortfolios = usePortfolioList();

  return useMemo(
    () => pipe(allPortfolios, RD.map(A.findFirst(checkById(portfolioId))), RD.toOption, O.flatten),
    [portfolioId, allPortfolios],
  );
};
