import * as RD from '@devexperts/remote-data-ts';

import { response } from '@api/helpers';
import { privateAPIv2 } from '~/api/base';
import { ApiError, mapApiError } from '@models/ApiError';
import { PaginatedResponse } from '~/shared/dtoTemplates';

import { AssetDTO, AssetEntity } from '../../asset.types';
import { mapAssetDtoToDomain } from '../../helpers/mapAssetDtoToDomain';

type Result = {
  hasNext: boolean;
  hasPrevious: boolean;
  totalResultsCount: number;
  results: AssetEntity[];
};

export type SearchResultData = RD.RemoteData<ApiError, Result>;

export const searchApi = (query: string, page: number = 1) => {
  return response(privateAPIv2.get<PaginatedResponse<AssetDTO>>(`/assets/search/`, { params: { page, query } }))
    .then((response) => ({
      hasNext: Boolean(response.next),
      hasPrevious: Boolean(response.previous),
      totalResultsCount: response.count,
      results: response.results.map(mapAssetDtoToDomain),
    }))
    .then<SearchResultData>(RD.success)
    .catch((e): SearchResultData => RD.failure(mapApiError(e)));
};
