import { useSelector } from 'react-redux';

import { BECOME_NASTY_WITH_NOTIFICATIONS_BY_THIS_VISIT } from '@constants/application';

import { Profile } from '@models/profile';

import { showEmailVerificationRequired } from '~/domain/events/profile';
import { getVisitsSinceLogin, isEmailVerificationClosed } from '@services/application';

import { selectProfile } from '@store/profile/selectors';

const isEmailVerificationNotificationRequired = (profile?: Profile | null) => {
  if (!profile) {
    return false;
  }
  if (profile.email_confirmed) return false;
  if (isEmailVerificationClosed()) return false;
  return getVisitsSinceLogin() >= BECOME_NASTY_WITH_NOTIFICATIONS_BY_THIS_VISIT;
};

export const usePrivateNotifications = () => {
  const profile = useSelector(selectProfile);

  if (isEmailVerificationNotificationRequired(profile)) {
    showEmailVerificationRequired();
  }
};
