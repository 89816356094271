import * as RD from '@devexperts/remote-data-ts';
import { constVoid } from 'fp-ts/function';

import { ApiError, mapApiError } from '@models/ApiError';
import { privateAPIv2 } from '~/api/base';
import { response } from '@api/helpers';

export type RemoveHoldingResult = RD.RemoteData<ApiError, null>;

export type RemoveHoldingParams = {
  ticker: string;
  portfolioId: string;
};

export const removeHoldingApi = (params: RemoveHoldingParams, onSuccess = constVoid) => {
  return response(privateAPIv2.delete(`/portfolios/${params.portfolioId}/holdings/${params.ticker}/`))
    .then((): RemoveHoldingResult => RD.success(null))
    .then((data) => {
      onSuccess();
      return data;
    })
    .catch((e): RemoveHoldingResult => RD.failure(mapApiError(e)));
};
