import styled from 'styled-components';

import { gaps } from '~/ui/kit/constants/sizes';
import { fontSize } from '~/ui/kit/constants/fonts';
import { media } from '~/ui/utils/media';

import { Card } from '~/ui/kit/organisms/Card';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${gaps.s}px;

  ${media.md`
    width: 100%;
    flex-direction: row;
    gap: ${gaps.m}px;
  `};
`;

export const Item = styled(Card).attrs({ $fs: 's' })`
  ${fontSize};
  color: var(--eko-third-color);
  font-weight: bold;

  ${media.md`
    width: 100%;
  `};
`;

export const Name = styled.div.attrs({ $fs: 'm' })`
  ${fontSize};
  font-weight: bold;
  color: var(--eko-title-color);
`;
