import { useCallback, useState } from 'react';
import * as RD from '@devexperts/remote-data-ts';

import { getMarketInfo } from '../api';

import { MarketInfoData } from '../market.types';

export const useMarketInfo = () => {
  const [data, setData] = useState<MarketInfoData>(RD.initial);

  const load = useCallback(() => {
    setData(RD.pending);

    getMarketInfo().then(setData);
  }, []);

  return [data, load] as const;
};
