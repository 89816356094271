import * as RD from '@devexperts/remote-data-ts';
import { response } from '@api/helpers';
import { privateAPIv2 } from '~/api/base';

import { mapApiError } from '@models/ApiError';

import { mapTopicsDtoToDomain } from '../../helpers/mapTopicDtoToDomain';
import { TopicDto, TopicsData } from '../../topic.types';

export const getTopicsInfoApi = async (): Promise<TopicsData> => {
  return await response<TopicDto[]>(privateAPIv2.get('/topics/'))
    .then(mapTopicsDtoToDomain)
    .then(RD.success)
    .catch((e) => RD.failure(mapApiError(e)));
};
