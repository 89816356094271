import { AnyAction } from 'redux';

import { FetchableState } from '@store/common/schemas';
import { LoginTypes } from '@store/authentication/types';
import { isPayloadSafetyType } from '@store/util';

export interface AuthenticationState {
  login: FetchableState;
}

const initialState: AuthenticationState = {
  login: {
    isLoading: false,
    error: '',
  },
};

export default function authenticationReducer(
  state: AuthenticationState = initialState,
  { type, payload }: AnyAction,
): AuthenticationState {
  if (isPayloadSafetyType(type, LoginTypes)) {
    return { ...state, login: payload };
  }
  return state;
}
