import styled from 'styled-components';

import { Label } from '~/ui/kit/organisms/Form/Form.organism.styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  ${Label} {
    font-weight: normal;
  }
`;

export const Description = styled('span')`
  color: var(--eko-primary-color);
  font-weight: 400;
`;
