import { EditACHValuePlain } from '../api/types';
import { BankAccount } from '../types';

export const checkIfEditAccount = (targetBankAccount: BankAccount) => (editValue: EditACHValuePlain) => {
  switch (editValue.type) {
    case 'saved-manual':
    case 'saved-plaid':
    case 'platform': {
      return editValue.id === targetBankAccount.id;
    }
    case 'manual': {
      return editValue.account.endsWith(targetBankAccount.lastDigits);
    }

    case 'plaid': {
      return (editValue.account || '').endsWith(targetBankAccount.lastDigits);
    }
  }
};
