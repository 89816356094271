import { useMemo } from 'react';
import * as RD from '@devexperts/remote-data-ts';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';

import { checkById } from '@utils/compare';
import { useTopics } from '../useTopics';
import { fromOption } from '@utils/RemoteData';

export const useTopicById = (topicId: number) => {
  const [topics] = useTopics();

  return useMemo(
    () =>
      pipe(
        topics,
        RD.map(A.findFirst(checkById(topicId))),
        RD.chain(fromOption(() => RD.failure({ code: 404, message: `No topic (${topicId}) info` }))),
      ),
    [topics, topicId],
  );
};
