import { spawn } from 'redux-saga/effects';

import {
  watchAlpacaAccountSaga,
  loadAlpacaAccountAction,
  setAlpacaAccount,
  AlpacaAccountReducer,
} from './alpaca_account.store';
import { isAccountVerifiedSelector } from './alpaca_account.selectors';

function* sagas() {
  yield spawn(watchAlpacaAccountSaga);
}

export { loadAlpacaAccountAction, sagas, setAlpacaAccount, AlpacaAccountReducer, isAccountVerifiedSelector };
