import { ACHRelationship } from '@models/transfers';
import { getLastDigits } from '@utils/getLastDigits.helper';
import { mapNumberDto } from '~/shared/dtoMappers';

import { BankAccount } from '../types';
import { mapAccountType } from '../helpers';

export const mapAchAccountToBankAccount = (ach: ACHRelationship): BankAccount => ({
  id: ach.id,
  displayName: ach.displayName || ach.type,
  type: mapAccountType(ach.type),
  lastDigits: getLastDigits(ach.lastDigits),
  // todo recheck for portfolioId value
  portfolioId: '',
  // createdAt: mapIsoDate(ach.created_at),
  balance: ach.balance === undefined || ach.balance === null ? undefined : mapNumberDto(ach.balance),
  connectionType: ach.connectionType,
});
