import { call, put, takeLatest } from 'redux-saga/effects';
import * as RD from '@devexperts/remote-data-ts';

import * as builder from '@store/builder';
import { mapApiError } from '@models/ApiError';

import { getTopicsInfoApi } from '../api/getTopicsInfo';
import { TopicsData } from '../topic.types';

const initialState: TopicsData = RD.initial;

export const actions = builder.getModuleActions('Topics');

export const setAction = (payload: TopicsData) => ({
  type: actions.SET,
  payload,
});

type TopicsAction = ReturnType<typeof setAction>;

export const TopicsReducer = (state = initialState, action: TopicsAction) => {
  switch (action.type) {
    case actions.SET: {
      return action.payload;
    }
  }

  return state;
};

export const loadAction = builder.buildRequestAction(actions.FETCH);

function* loadTopicsSaga() {
  yield put(setAction(RD.pending));

  try {
    const mappedResult: TopicsData = yield call(getTopicsInfoApi);
    yield put(setAction(mappedResult));
  } catch (e) {
    yield put(setAction(RD.failure(mapApiError(e))));
  }
}

export function* watchLoadTopicsSaga() {
  yield takeLatest(actions.FETCH, loadTopicsSaga);
}
