import React from 'react';

import { formatMoney } from '@utils/money';
import { PortfolioListItem, getPortfolioAvailableBalance } from '~/entities/protfolio';

import * as S from './PortfolioSelectItem.styled';

type Props = PortfolioListItem & {
  className?: string;
  isSelected: boolean;
};

export const PortfolioSelectItem: React.FC<Props> = (props) => {
  return (
    <S.Container>
      <S.NameContainer>
        <S.Name>{props.name}</S.Name>
        {props.isSelected ? <S.SelectedIcon /> : null}
      </S.NameContainer>
      <S.Balance>{formatMoney(getPortfolioAvailableBalance(props))}</S.Balance>
    </S.Container>
  );
};
