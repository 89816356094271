import * as RD from '@devexperts/remote-data-ts';
import { constVoid } from 'fp-ts/function';

import { response } from '@api/helpers';
import { mapApiError } from '@models/ApiError';
import { privateAPIv2 } from '~/api/base';

import { mapPortfolioItemDto } from '../list/helpers/mapDtoToDomain';
import { PortfolioItemDTO, PortfolioListItem } from '../portfolio.types';

export const convertToSelfDirected = (
  portfolioId: string,
  onSuccess: (portfolio: PortfolioListItem) => void = constVoid,
) => {
  return response(privateAPIv2.post<PortfolioItemDTO>(`/portfolios/${portfolioId}/switch-self-direct/`))
    .then(mapPortfolioItemDto)
    .then((portfolioItem) => {
      onSuccess(portfolioItem);
      return RD.success(portfolioItem);
    })
    .catch((e) => RD.failure(mapApiError(e)));
};
