import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { loadAction } from '../../store/holdings.store';

export const useUpdatePortfolioHoldings = () => {
  const dispatch = useDispatch();

  return useCallback(
    (portfolioId: string, keepCache?: boolean) => {
      if (portfolioId) {
        dispatch(loadAction(portfolioId, keepCache));
      }
    },
    [dispatch],
  );
};
