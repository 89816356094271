import * as RD from '@devexperts/remote-data-ts';

import { IsoDate } from '@models/common';
import { response } from '@api/helpers';
import { privateAPIv2 } from '~/api/base';
import { onInfoNotification } from '@domain/events/info';
import { BROKER_LOADING_ERROR } from '@domain/constants/error-messages';
import { mapApiError } from '@models/ApiError';
import { getEndDate } from '@utils/graphOptions';

import { AssetPerformanceResponseData, AssetPerformanceResponseDTO } from '../../asset.types';
import { mapAssetPerformanceDtoToDomain } from '../../helpers';

type TimeFrame = '1Week' | '1Day';

export type GetAssetPerformanceParams = {
  timeFrame?: TimeFrame;
  startDate: IsoDate;
  endDate?: IsoDate;
};

export const getAssetPerformance = (ticker: string, params: GetAssetPerformanceParams) => {
  return response(
    privateAPIv2.get<AssetPerformanceResponseDTO>(`/assets/${ticker}/bars/`, {
      params: {
        start_date: params.startDate,
        end_date: params.endDate || getEndDate(),
        timeframe: params.timeFrame || '1Day',
      },
    }),
  )
    .catch((e) => {
      onInfoNotification(BROKER_LOADING_ERROR);
      throw e;
    })
    .then(mapAssetPerformanceDtoToDomain)
    .then<AssetPerformanceResponseData>(RD.success)
    .catch((e): AssetPerformanceResponseData => RD.failure(mapApiError(e)));
};
