import styled from 'styled-components';

import { fontSize } from '~/ui/kit/constants/fonts';
import { gaps } from '~/ui/kit/constants/sizes';

import { Skeleton as Skeletons } from '~/ui/kit/atoms/Skeleton';
import { Title as BaseTitle } from '~/ui/kit/atoms/Title';
import { Card } from '~/ui/kit/organisms/Card';

export const CardSection = styled(Card).attrs({ $fs: 's' })`
  ${fontSize};
  color: var(--eko-third-color);
`;

export const Skeleton = styled(Skeletons.LineSkeleton)``;

export const Title = styled(BaseTitle).attrs({ level: 5, $fs: 's' })`
  margin-bottom: ${gaps.xxs}px;
  ${fontSize};
`;

export const Text = styled.div.attrs({ $fs: 's' })`
  color: var(--eko-third-color);
  ${fontSize};
`;
