import React from 'react';

import * as Styled from './FallbackDescription.styled';

export const FallbackDescription: React.FC<{ onClick: VoidFunction }> = (props) => {
  return (
    <Styled.Container>
      <Styled.Text>If you couldn’t find your bank, you can connect account manually or</Styled.Text>
      <Styled.BackButton onClick={props.onClick}>
        <Styled.Arrow />
        Go to account selection
      </Styled.BackButton>
    </Styled.Container>
  );
};
