import * as RD from '@devexperts/remote-data-ts';

import { mapApiError } from '@models/ApiError';
import { response } from '@api/helpers';
import { privateAPIv2 } from '~/api/base';

import { MarketInfoData, MarketInfoDTO } from '../market.types';
import { mapMarketInfoDtoToDomainHelper } from '../helpers';

export const getMarketInfo = (): Promise<MarketInfoData> => {
  return response(privateAPIv2.get<MarketInfoDTO>('/clock/'))
    .then(mapMarketInfoDtoToDomainHelper)
    .then(RD.success)
    .catch((e) => RD.failure(mapApiError(e)));
};
