import { BrokerageAccountDTO, BrokerageAccount } from '../brokerageAccount.entity.types';

const mapPhoneNumber = (phone?: string) => (phone && phone[0] !== '+' && phone.length === 10 ? `+1${phone}` : phone);

const mapContact = (dto: BrokerageAccountDTO['contact']): BrokerageAccount['contact'] => ({
  email: dto.email_address || undefined,
  phone: mapPhoneNumber(dto.phone_number),
  city: dto.city || undefined,
  postalCode: dto.postal_code || undefined,
  state: dto.state || undefined,
  address: dto.street_address && dto.street_address[0],
});

const mapIdentity = (dto: BrokerageAccountDTO['identity']): BrokerageAccount['identity'] => ({
  name: dto.given_name,
  maxIncome: dto.liquid_net_worth_max ? parseInt(dto.liquid_net_worth_max.toString(), 10) : Number.MAX_VALUE,
  minIncome: dto.liquid_net_worth_min ? parseInt(dto.liquid_net_worth_min.toString(), 10) : 0,
  fundingSource: (dto.funding_source || [])[0],
});

const mapTrustedContact = (dto: BrokerageAccountDTO['trusted_contact']): BrokerageAccount['trustedContact'] => {
  if (!dto) {
    return {};
  }

  return {
    email: dto.email_address || undefined,
    phone: dto.phone_number || undefined,
    familyName: dto.family_name || undefined,
    givenName: dto.given_name || undefined,
  };
};

const mapDisclosures = (dto: BrokerageAccountDTO['disclosures']): BrokerageAccount['disclosures'] => ({
  isAffiliatedExchangeOrFinra: dto.is_affiliated_exchange_or_finra || false,
  isControlPerson: dto.is_control_person || false,
  immediateFamilyExposed: dto.immediate_family_exposed || false,
  employmentStatus: dto.employment_status,
  employerAddress: dto.employer_address,
  employerName: dto.employer_name,
  employmentPosition: dto.employment_position,
  isPoliticallyExposed: dto.is_politically_exposed,
});

export const mapDtoToDomain = (dto: BrokerageAccountDTO): BrokerageAccount => ({
  contact: mapContact(dto.contact),
  identity: mapIdentity(dto.identity),
  trustedContact: mapTrustedContact(dto.trusted_contact),
  disclosures: mapDisclosures(dto.disclosures),
});
