import { useCallback, useMemo, useRef, useState } from 'react';
import * as RD from '@devexperts/remote-data-ts';
import debounce from 'lodash/debounce';
import { pipe } from 'fp-ts/function';

import { SearchResultData, searchApi } from '../../api';

export const useSearchAsset = () => {
  const [state, setState] = useState<SearchResultData>(RD.initial);
  const pageRef = useRef(1);
  const queryRef = useRef<string | undefined>();

  const loadResults = useCallback((query: string = '', page: number = 1) => {
    setState(RD.pending);
    queryRef.current = query;
    searchApi(query, page).then(setState);
  }, []);

  const debouncedSetQuery = useMemo(
    () =>
      debounce((query: string) => {
        pageRef.current = 1;

        loadResults(query, pageRef.current);
      }, 1000),
    [loadResults],
  );

  const setQuery = useCallback(
    (query: string) => {
      debouncedSetQuery(query);
    },
    [debouncedSetQuery],
  );

  const loadMore = useMemo(() => {
    return pipe(
      state,
      RD.map((value) => {
        if (value.hasNext) {
          return () => {
            pageRef.current++;
            loadResults(queryRef.current, pageRef.current);
          };
        }
      }),
      RD.toUndefined,
    );
  }, [loadResults, state]);

  return [state, setQuery, loadMore] as const;
};
