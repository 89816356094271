import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import * as routes from 'src/routes/names';

import { ACCESS_TOKEN_PARAM, NEXT_PATH_PARAM, REFRESH_TOKEN_PARAM } from '~/authentication/auth';

import { getNextRoute } from '@services/application';

import * as appActions from '@store/application/actions';
import type { LoadAppPayload } from '@store/application/actions';
import { selectIsApplicationLoading, selectIsExternalUser } from '@store/application/selectors';

export const useAuthentication = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const isExternal = useSelector(selectIsExternalUser);
  const appLoading = useSelector(selectIsApplicationLoading);

  useEffect(
    () => {
      const query = new URLSearchParams(location.search);
      let payload: LoadAppPayload | null = null;

      const accessToken = query.get(ACCESS_TOKEN_PARAM);
      const refreshToken = query.get(REFRESH_TOKEN_PARAM);

      if (accessToken && refreshToken) {
        payload = {
          tokens: {
            accessToken,
            refreshToken,
          },
          successCallback: () => {
            const query = new URLSearchParams(location.search);
            const next = query.get(NEXT_PATH_PARAM);

            [ACCESS_TOKEN_PARAM, REFRESH_TOKEN_PARAM, NEXT_PATH_PARAM].forEach((el) => query.delete(el));

            const hPayload: Record<string, string> = { search: query.toString() };

            if (next) {
              hPayload.pathname = getNextRoute(next);
            }

            history.replace(hPayload);
          },
        };
      }

      dispatch(appActions.loadApplication(payload));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (isExternal && location.pathname !== routes.BANNO) {
      let url = routes.GET_STARTED_EXTERNAL;
      const query = new URLSearchParams(history.location.search).toString();

      if (query) {
        url += `?${query}`;
      }

      history.replace(url);
    }
  }, [history, isExternal]);

  return appLoading;
};
