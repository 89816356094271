import { call, put, takeLeading } from 'redux-saga/effects';

import * as appActions from '@store/application/actions';
import * as authActions from '@store/authentication/actions';
import AuthTypes from '@store/authentication/types';
import { LoginAction } from '@store/authentication/actions';

import * as services from '@services/auth';

function* login({ payload: authData }: LoginAction) {
  try {
    yield put(authActions.loginStarted());
    yield call(() => services.login(authData));
    yield put(authActions.loginSucceeded());
    yield put(appActions.loadApplication());
  } catch (e) {
    yield put(authActions.loginFailed((e as Error).message));
  }
}

function* logout() {
  yield put(appActions.loadingAppStarted());
  yield call(() => services.logout());
  yield put(appActions.reset());
  yield put(appActions.loadingAppSucceeded());
}

export default function* authSaga() {
  yield takeLeading(AuthTypes.LOGIN, login);
  yield takeLeading(AuthTypes.LOGOUT, logout);
}
