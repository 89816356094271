import { AxiosPromise } from 'axios';

import { privateAPIv2 } from '~/api/base';
import { PortfolioPerformanceResponseDTO, PortfolioPerformanceRequestParams } from '@api/schemas/portfolio';

import { PortfolioItemDTO, CreatePortfolioDTORequest } from '~/entities/protfolio/portfolio.types';

const portfolioAPI = {
  fetchPortfolioPerformance: (
    portfolioId: string,
    {
      params,
    }: {
      params: PortfolioPerformanceRequestParams;
    },
  ): AxiosPromise<PortfolioPerformanceResponseDTO> => {
    return privateAPIv2.post(`/portfolios/${portfolioId}/performance/`, { ...params });
  },
  createPortfolio: (profileData: CreatePortfolioDTORequest): AxiosPromise<PortfolioItemDTO> =>
    privateAPIv2.post('/portfolios/', profileData),
};

export default portfolioAPI;
