import { response } from '@api/helpers';
import { privateAPIv2 } from '~/api/base';

import { mapPortfolioArrayWithSortDto } from '../helpers/mapDtoToDomain';

export const getPortfolioList = () => {
  return async () => {
    return response(privateAPIv2.get('/portfolios/')).then(mapPortfolioArrayWithSortDto);
  };
};
