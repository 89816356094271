import { applyMiddleware, combineReducers, compose, createStore } from 'redux';

import appReducer from './application';
import organizationReducer from './organization';
import profileReducer from './profile';
import { init as initSaga } from '~/data/middlewares/saga';
import middlewares from '~/data/middlewares';
import application from '~/reducers/application';
import AppTypes from '@store/application/types';
import brokerAccountReducer from '@store/broker_account';
import authenticationReducer from '@store/authentication';
import onboardingReducer from '@store/onboarding';
import { TransfersReducer } from '~/entities/transfers';
import onboardingAlpacaReducer from '@store/alpaca_onboarding';
import { AlpacaAccountReducer } from '@store/alpaca_account';
import { achReducer } from '~/entities/ach';
import { BrokerageAccount } from '~/entities/brokerageAccount/api/stores';
import { profileSettingsReducer, userSettingsReducer } from '@store/settings';
import { PortfolioReducer } from '~/entities/protfolio';
import { TopicsReducer } from '~/entities/topic';
import { HoldingsReducer } from '~/entities/Holding';

const reducers = combineReducers({
  Profile: profileReducer,
  Organization: organizationReducer,
  Application: appReducer,
  BrokerAccount: brokerAccountReducer,
  Authentication: authenticationReducer,
  Onboarding: onboardingReducer,
  AlpacaOnboarding: onboardingAlpacaReducer,
  application,
  ACH: achReducer,
  BrokerageAccount,
  AlpacaAccount: AlpacaAccountReducer,
  Transfers: TransfersReducer,
  Settings: profileSettingsReducer,
  UserSettings: userSettingsReducer,
  // new API
  Portfolio: PortfolioReducer,
  Holdings: HoldingsReducer,
  Topics: TopicsReducer,
});

type ReduxGlobalStore = ReturnType<typeof reducers>;

declare module 'react-redux' {
  export interface DefaultRootState extends ReduxGlobalStore {}
}

// for now no need to check typings here as RESET action is called only for logout scenario
const EMPTY_STORE_STATE = {} as ReduxGlobalStore;
let composeEnhancers = compose;

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof composeEnhancers;
  }
}

if (typeof window !== 'undefined') {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || composeEnhancers;
}

const rootReducer = (state: ReduxGlobalStore, action: any) => {
  if (action.type === AppTypes.RESET) {
    state = EMPTY_STORE_STATE;
  }
  return reducers(state, action);
};

// eslint-disable-next-line
// @ts-ignore
const store = createStore(rootReducer, EMPTY_STORE_STATE, composeEnhancers(applyMiddleware(...middlewares)));

initSaga();

export default store;
