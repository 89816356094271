import React from 'react';

import { LoadableComponentProps } from '@utils/LoadableComponentProps';
import { truncateForFullSentence } from '~/ui/utils/string';
import { LinesSkeleton } from '~/ui/kit/atoms/Skeleton';

import * as S from './HoldingDescription.styled';

type Props = {
  className?: string;
} & LoadableComponentProps<{ description?: string }>;

export const HoldingDescription: React.FC<Props> = (props) => {
  return (
    <S.CardSection className={props.className}>
      <S.Title>About</S.Title>
      {props.isLoading ? (
        <LinesSkeleton lines={6} />
      ) : (
        <S.Text>{truncateForFullSentence(props.description || 'Description unavailable')}</S.Text>
      )}
    </S.CardSection>
  );
};
