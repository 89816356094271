import { useDispatch, useSelector } from 'react-redux';
import { topicsSelector } from '../../selectors';
import { useCallback } from 'react';
import { loadAction } from '../../store';

export const useTopics = () => {
  const dispatch = useDispatch();
  const topics = useSelector(topicsSelector);

  const reload = useCallback(() => {
    dispatch(loadAction());
  }, [dispatch]);

  return [topics, reload] as const;
};
