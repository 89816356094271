import { AxiosPromise } from 'axios';

import { privateAPI, privateAPIv2 } from '~/api/base';
import * as schemas from '@api/schemas/broker';

import { BrokerDocumentDTO } from '@models/broker';

import { BrokerageAccountDTO } from '~/entities/brokerageAccount';

const brokerApi = {
  fetchBrokerAgeAccount: (): AxiosPromise<BrokerageAccountDTO> => privateAPI.get('/broker/brokerage-account/'),
  changeAlpacaAccount: (data: Partial<BrokerageAccountDTO>): AxiosPromise<BrokerageAccountDTO> =>
    privateAPI.patch('/broker/alpaca-account/me/', data),

  // @deprecated
  fetchPortfolioHistory: (
    portfolioId: string,
    { params }: { params: object },
  ): AxiosPromise<schemas.PortfolioHistoryDTO> => {
    return privateAPIv2.get(`/portfolios/${portfolioId}/history/`, { params });
  },

  fetchAccountTradingInfo: (): AxiosPromise<schemas.TradingAccountResponse> =>
    privateAPI.get(`/broker/trading-account/`),

  validateEmail: (email: string): AxiosPromise<schemas.ValidateEmailResponse> =>
    privateAPI.post('/broker/email/check/', { email }),

  fetchAlpacaAccount: (): AxiosPromise<schemas.AlpacaAccountInfoDTO> => privateAPI.get('/broker/alpaca-account/me/'),
  closeAccount: (data: schemas.CloseAccountParams): AxiosPromise<unknown> =>
    privateAPI.post('/broker/alpaca-account/close/', data),
  // todo revise typings
  fetchDocumentsBy: (type: string): AxiosPromise<BrokerDocumentDTO[]> =>
    privateAPI.get(`/broker/documents/?type=${type}`),
  submitDocuments: (data: object): AxiosPromise<unknown> => privateAPI.post('/broker/alpaca-account/documents/', data),
  // todo revise when KYC.jsx moves to tsx
  submitKYC: (data: object): AxiosPromise<unknown> => privateAPI.post('/broker/alpaca-account/me/kyc/', data),
  downloadDocument: (docID: string | number): AxiosPromise<{ location?: string }> =>
    privateAPI.get(`/broker/documents/${docID}/download/`),
  onboarding: (data: schemas.CreateAlpacaAccountInfoDTO): AxiosPromise<unknown> =>
    privateAPI.post('/broker/alpaca-account/', data),
};

export default brokerApi;
