import { spawn } from 'redux-saga/effects';

import { watchLoadPortfolioListSaga } from './portfolioList.store';

function* sagas() {
  yield spawn(watchLoadPortfolioListSaga);
}

export { sagas as portfolioListSagas };

export {
  addPortfolioListItem,
  loadAction as loadPortfolioList,
  actions as portfolioListActions,
  setAction as setPortfolioAction,
  PortfolioListReducer,
  updatePortfolioItem,
  deletePortfolioItem as deletePortfolioItemAction,
} from './portfolioList.store';
