export enum ConnectErrorType {
  BANK_NOT_FOUND = 'BANK_NOT_FOUND',
  CONNECTION_FAILED = 'CONNECTION_FAILED',
  UNKNOWN = 'UNKNOWN',
  FAILED_TO_CREATE_TOKEN = 'FAILED_TO_CREATE_TOKEN',
}

export type ConnectError = {
  type: ConnectErrorType;
  messages?: Array<string>;
};
