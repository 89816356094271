import { spawn } from 'redux-saga/effects';

import {
  watchLoadProfileSettings,
  watchModifyProfileSettings,
  profileSettingsReducer,
  loadProfileSettingAction,
  modifyProfileSettingAction,
} from './profileSettings.store';

import {
  watchLoadUserSettings,
  watchModifyUserSettings,
  userSettingsReducer,
  loadUserSettingAction,
  modifyUserSettingAction,
} from './userSettings.store';

export * from './profileSettings.selectors';
export * from './userSettings.selectors';
export * from './settings.store.types';

function* profileSettingsSaga() {
  yield spawn(watchLoadProfileSettings);
  yield spawn(watchModifyProfileSettings);
}

function* userSettingsSaga() {
  yield spawn(watchLoadUserSettings);
  yield spawn(watchModifyUserSettings);
}

export {
  loadProfileSettingAction,
  modifyProfileSettingAction,
  profileSettingsReducer,
  profileSettingsSaga,
  loadUserSettingAction,
  modifyUserSettingAction,
  userSettingsReducer,
  userSettingsSaga,
};
