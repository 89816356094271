import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { pipe } from 'fp-ts/function';
import * as RD from '@devexperts/remote-data-ts';
import * as A from 'fp-ts/Array';

import { PortfolioTypeValue } from '../../../portfolio.types';
import { portfolioListSelector } from '../../selectors';
import { PortfolioList } from '../../portfolioList.types';
import { portfolioListEq } from '../../../helpers';

export const usePortfolioList = (type?: PortfolioTypeValue): PortfolioList => {
  const portfolioList = useSelector(portfolioListSelector, portfolioListEq.equals);

  return useMemo(
    () =>
      pipe(
        portfolioList,
        RD.map(
          A.filter(({ portfolioType }) => {
            if (!type) {
              return true;
            }

            return portfolioType === type;
          }),
        ),
      ),
    [portfolioList, type],
  );
};
