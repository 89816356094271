enum Types {
  LOAD = 'LOAD',
  LOADING_STARTED = 'LOADING_STARTED',
  LOADING_SUCCEEDED = 'LOADING_SUCCEEDED',
  LOADING_FAILED = 'LOADING_FAILED',

  SET_NOTIFICATIONS = 'SET_NOTIFICATIONS',
  SET_IS_EXTERNAL = 'SET_IS_EXTERNAL',
  SET_MENU = 'SET_MENU',

  RESET = 'RESET',
}

export default Types;
