import { NotificationType } from '@models/application/notifications';
import { VerifyEmailNotification } from '@models/application/notifications/email';

import { addNotification } from '@utils/notifications';

export const showEmailVerificationRequired = () => {
  addNotification({
    type: NotificationType.VerifyEmail,
    payload: {
      config: { closable: true },
      content: {},
    },
  } as VerifyEmailNotification);
};

export const onEmailChange = () => {
  showEmailVerificationRequired();
};
