import { AxiosPromise } from 'axios';

import { privateAPI } from '~/api/base';
import { Organization } from '@models/organization';

const organizationAPI = {
  retrieve: (): AxiosPromise<Organization> => privateAPI.get('/platform/organization/'),
};

export default organizationAPI;
