import * as RD from '@devexperts/remote-data-ts';

import { PortfolioArrayDTO, PortfolioList } from '~/entities/protfolio/list';

import { mapPortfolioArrayDto } from '../helpers/mapDtoToDomain';
import { mapApiError } from '@models/ApiError';
import { sleep } from '@utils/dev/async';

const mock: PortfolioArrayDTO = [
  {
    id: '6efa2db4-cbb8-496e-8957-e97310d61787',
    name: 'Self-directed 2',
    created_at: '2024-08-09T13:17:27.196906Z',
    investment_reason: 'DONT_KNOW',
    equity: '354.00',
    cash: '354.00',
    cash_reserved: '100.00',
    topics: [],
    portfolio_type: 'SELF_DIRECT',
    prebuilt_id: null,
    risk_level: 1,
    bank_account_id: 'd362efed-87a7-4310-90f8-d1be9cdbd6ec',
    first_deposit_status: 'PROCESSED',
    performance: '350',
  },
  // {
  //   id: '65871cae-bcff-4f8d-9a77-cbaff446a579',
  //   name: 'Self-directed 1',
  //   created_at: '2024-08-09T12:51:12.676708Z',
  //   investment_reason: 'DONT_KNOW',
  //   equity: '111.01',
  //   cash: '104.36',
  //   reserved_cash: '10.00',
  //   topics: [],
  //   portfolio_type: 'SELF_DIRECT',
  //   prebuilt_id: null,
  //   risk_level: 1,
  //   bank_account_id: '3c9281e5-7156-483c-ba65-26d71f22d047',
  //   first_deposit_status: 'PROCESSED',
  //   performance: '-1488',
  // },
  // {
  //   id: 'e0ef76f8-406a-4069-816c-05a3ff77ef22',
  //   name: 'Self-directed',
  //   created_at: '2024-08-27T08:11:16.614349Z',
  //   investment_reason: 'DONT_KNOW',
  //   equity: '5.00',
  //   cash: '5.00',
  //   reserved_cash: '0.00',
  //   topics: [],
  //   portfolio_type: 'SELF_DIRECT',
  //   prebuilt_id: null,
  //   risk_level: 1,
  //   bank_account_id: '445eb614-b5eb-4842-93bc-5ccf98c27e35',
  //   first_deposit_status: 'NOT_RECEIVED',
  // },
];
export const getTopThreePortfolioList = (): Promise<PortfolioList> =>
  // response(privateAPIv2.get('/portfolios/topThree'))
  sleep(1000)
    .then(() => mock)
    .then(mapPortfolioArrayDto)
    .then(RD.success)
    .catch((err) => RD.failure(mapApiError(err)));
