import { Eq } from 'fp-ts/Eq';
import * as A from 'fp-ts/Array';
import * as RD from '@devexperts/remote-data-ts';

import { HoldingsEntityData, HoldingEntity } from '../Holding.entity.types';

export const holdingEq: Eq<HoldingEntity> = {
  equals: (a, b) =>
    a.isHighlighted === b.isHighlighted &&
    a.pendingState === b.pendingState &&
    a.marketValue === b.marketValue &&
    a.performance === b.performance &&
    a.percentOfAllPortfolio === b.percentOfAllPortfolio &&
    a.quantity === b.quantity &&
    a.totalReturns === b.totalReturns,
};

export const holdingsEq = A.getEq(holdingEq);

export const holdingsDataEq: Eq<HoldingsEntityData> = RD.getEq({ equals: (a, b) => a.code === b.code }, holdingsEq);
