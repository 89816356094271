import { useMemo } from 'react';
import * as RD from '@devexperts/remote-data-ts';
import { pipe } from 'fp-ts/function';

import { ApiError } from '@models/ApiError';
import { sequenceS } from '@utils/RemoteData';

import { HoldingType, HOLDING_TYPE } from '../../Holding.entity.types';
import { useHoldingsByType } from '../useHoldingsByType';

type Counts = Record<HoldingType, number>;

export const useHoldingTypeCounts = (): RD.RemoteData<ApiError, Counts> => {
  const etfs = useHoldingsByType(HOLDING_TYPE.ETF);
  const bonds = useHoldingsByType(HOLDING_TYPE.BOND);
  const stocks = useHoldingsByType(HOLDING_TYPE.STOCK);

  return useMemo(
    () =>
      pipe(
        sequenceS({
          etfs,
          bonds,
          stocks,
        }),
        RD.map(({ etfs, bonds, stocks }) => ({
          [HOLDING_TYPE.ETF]: etfs.length,
          [HOLDING_TYPE.BOND]: bonds.length,
          [HOLDING_TYPE.STOCK]: stocks.length,
        })),
      ),
    [etfs, bonds, stocks],
  );
};
