import { AxiosError, AxiosPromise, AxiosResponse } from 'axios';

export const getResponseData = (response: AxiosResponse) => response.data;

// todo revise and check other
const DEFAULT_ERROR_MESSAGE = 'Processing error, please try again';

const getErrorObjectStringValue = (val: unknown): string => {
  if (val !== null && typeof val === 'object') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if ('message' in val && typeof val.message === 'string') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return val.message;
    }

    const values = Object.values(val);

    for (let i = 0; i < values.length; i++) {
      const value = values[i];

      if (typeof value === 'string') {
        return value;
      }

      if (Array.isArray(value)) {
        if (value[0] && typeof value[0] === 'string') {
          return value[0];
        }
      }

      if (typeof value === 'object') {
        if ('msg' in value && typeof value.msg === 'string') {
          return value.msg;
        }
        if ('message' in value && typeof value.message === 'string') {
          return value.message;
        }
        const innerValue = getErrorObjectStringValue(value);

        if (innerValue) {
          return innerValue;
        }
      }
    }
  }

  return DEFAULT_ERROR_MESSAGE;
};

export const getResponseErrorMessage = (
  response: AxiosResponse | AxiosError | undefined,
  defaultErrorMessage = DEFAULT_ERROR_MESSAGE,
) => {
  let msg = defaultErrorMessage;

  if (response) {
    if ('data' in response) {
      const data = getResponseData(response);
      if (Array.isArray(data)) {
        msg = data[0] || msg;
      } else if (data?.non_field_errors) {
        if (Array.isArray(data?.non_field_errors)) {
          msg = data?.non_field_errors[0] || msg;
        }
        if (typeof data?.non_field_errors === 'string') {
          msg = data?.non_field_errors;
        }
      }
    } else {
      if (response.response && response.response.data) {
        const errorData = response.response.data;
        if (typeof errorData === 'object') {
          msg = getErrorObjectStringValue(errorData);
        }
      }
      msg = msg || response.message;
    }
  }
  return (msg || defaultErrorMessage).replace(/_/g, ' ');
};

export const response = async <T>(response: AxiosPromise<T>): Promise<T> =>
  response.then((res: AxiosResponse | AxiosError) => {
    if ((res as AxiosError).isAxiosError) {
      throw res;
    }
    return getResponseData(res as AxiosResponse);
  });

export function responseCallback<Fn extends (...args: any[]) => any, T>(
  func: (...args: Parameters<Fn>) => AxiosPromise,
): (...args: Parameters<Fn>) => Promise<T> {
  return (...args: Parameters<Fn>) => response<T>(func(...args));
}
