import { AssetPerformanceResponse, AssetPerformanceResponseDTO } from '../../asset.types';
import { getDateFromTimestamp } from '@utils/alpacaUtils';

export const mapAssetPerformanceDtoToDomain = (dto: AssetPerformanceResponseDTO): AssetPerformanceResponse => {
  const dtoBars: AssetPerformanceResponseDTO = dto || [];
  const priceValue = dtoBars[dtoBars.length - 1]?.close;

  const chartData: AssetPerformanceResponse['chartData'] = dtoBars.map((bar) => [
    getDateFromTimestamp(bar.timestamp, 'MM-DD-YYYY'),
    bar.close,
  ]);
  const chartValues = chartData.map((point) => point[1]);

  const performance = ((dtoBars[dtoBars.length - 1].close - dtoBars[0].close) / dtoBars[0].close) * 100;

  let maxRangeValue = Math.max(...chartValues) * 1.1;
  let minRangeValue = Math.min(...chartValues) * 0.9;

  if (chartValues.length === 0) {
    maxRangeValue = 0;
    minRangeValue = 0;
  }

  if (chartValues.length === 1) {
    maxRangeValue = chartValues[1];
    minRangeValue = chartValues[1];
  }

  const firstChangeValue = dtoBars[dtoBars.length - 1]?.close - dtoBars[0]?.close;
  const secondChangeValue = dtoBars[0]?.close;

  const plainChangeValue = Number(((firstChangeValue / secondChangeValue) * 100).toFixed(2));

  const changeValue = Number.isNaN(plainChangeValue) ? 0 : plainChangeValue;

  return {
    bars: dto,
    chartData,
    maxRangeValue,
    minRangeValue,
    changeValue,
    priceValue,
    performance,
  };
};
