import * as RD from '@devexperts/remote-data-ts';
import { DefaultRootState } from 'react-redux';
import { pipe } from 'fp-ts/function';
import { put, call, takeLatest, select } from 'redux-saga/effects';

import { ApiError } from '@models/ApiError';
import { response } from '@api/helpers';
import * as builder from '@store/builder';

import { userSettingsSelector } from './userSettings.selectors';
import { UserSettingsRD, UserSettings } from './settings.store.types';
import { userApi } from '@api/endpoints/user';

const action = builder.getModuleAction('USER_SETTINGS');

const FETCH = action('FETCH');
const SET = action('SET');
const MODIFY = action('MODIFY');

const initialState: UserSettingsRD = RD.initial;

const setUserInfo = (payload: UserSettingsRD) => ({
  type: SET,
  payload: payload,
});

type UserSettingsAction = ReturnType<typeof setUserInfo>;

export const modifyUserSettingAction = (payload: UserSettings) => ({
  type: MODIFY,
  payload,
});

type ModifyAction = ReturnType<typeof modifyUserSettingAction>;

export const userSettingsReducer = (state = initialState, action: UserSettingsAction) => {
  switch (action.type) {
    case SET: {
      return action.payload;
    }
    default:
      return state;
  }
};

function* loadUserSettings() {
  yield put(setUserInfo(RD.pending));

  try {
    const userSettings: UserSettings = yield call(response, userApi.getUserMetaInfo());

    const result: UserSettings = {
      experiments: userSettings?.experiments || [],
      isAppDisabled: userSettings?.isAppDisabled === undefined ? false : userSettings.isAppDisabled,
    };

    yield put(
      setUserInfo(
        userSettings
          ? RD.success(result)
          : RD.success({
              isAppDisabled: false,
            }),
      ),
    );
  } catch (e) {
    yield put(setUserInfo(RD.failure(e as unknown as ApiError)));
  }
}
export function* watchLoadUserSettings() {
  yield takeLatest(FETCH, loadUserSettings);
}
export const loadUserSettingAction = builder.buildRequestAction(FETCH);

function* modifyUserSettings(action: ModifyAction) {
  try {
    const state: DefaultRootState = yield select();
    const settingsState = userSettingsSelector(state);

    const requestData = { ...action.payload };

    const apiResponse: UserSettings = yield call(response, userApi.setUserMetaInfo(requestData));

    const result = pipe(
      settingsState,
      RD.map((data) => ({ ...data, ...apiResponse })),
      RD.alt(() => RD.success({ experiments: [], isAppDisabled: true } as UserSettings)),
    );
    yield put(setUserInfo(result));
  } catch (_) {
    const state: DefaultRootState = yield select();
    const settingsState = userSettingsSelector(state);
    const result = pipe(
      settingsState,
      RD.map((data) => ({ ...data })),
      RD.alt(() => RD.success({ experiments: [], isAppDisabled: true } as UserSettings)),
    );
    yield put(setUserInfo(result));
  }
}

export function* watchModifyUserSettings() {
  yield takeLatest(MODIFY, modifyUserSettings);
}
