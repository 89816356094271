import { Action } from 'redux';
import * as O from 'fp-ts/Option';

import * as builder from '@store/builder';
import { EditACHValue } from '../types';

const initialState: EditACHValue = O.none;

const actionFactory = builder.getModuleAction('EDIT_ACH');

const SET = actionFactory('SET');

type SetAction = Action<typeof SET> & {
  payload: typeof initialState;
};
export const EditACHReducer = (state = initialState, action: SetAction) => {
  switch (action.type) {
    case SET: {
      return action.payload;
    }

    default:
      return state;
  }
};

export const setEditACH = (payload: EditACHValue) => ({
  type: SET,
  payload: payload,
});
