import isAfter from 'date-fns/isAfter';
import { Ord } from 'fp-ts/Ord';

import { PortfolioListItem } from '../portfolio.types';

export const portfolioBalanceOrd: Ord<PortfolioListItem> = {
  compare: (a, b) => {
    if (a.equity === b.equity) {
      return 0;
    }
    return a.equity > b.equity ? -1 : 1;
  },
  equals: (a, b) => a.equity === b.equity && a.id === b.id,
};

export const portfolioCreationOrd: Ord<PortfolioListItem> = {
  compare: (a, b) => {
    if (!a.createdAt && !b.createdAt) {
      return 0;
    }

    if (!a.createdAt) {
      return 1;
    }
    if (!b.createdAt) {
      return -1;
    }

    return isAfter(new Date(a.createdAt), new Date(b.createdAt)) ? 1 : -1;
  },
  equals: (a, b) => a.equity === b.equity && a.id === b.id,
};
