import { useCallback, useState } from 'react';
import * as RD from '@devexperts/remote-data-ts';

import { getAssetPerformance, GetAssetPerformanceParams } from '../../api';
import { AssetPerformanceResponseData } from '../../asset.types';

export const useAssetPerformance = () => {
  const [state, setState] = useState<AssetPerformanceResponseData>(RD.initial);

  const loadData = useCallback((ticker: string, params: GetAssetPerformanceParams) => {
    setState(RD.pending);
    getAssetPerformance(ticker, params).then(setState);
  }, []);

  return [state, loadData] as const;
};
