import { mapNumberDto } from '~/shared/dtoMappers';

import { HoldingDTO, HoldingEntity } from '../Holding.entity.types';
import { mapHoldingType } from './mapHoldingType';
import { mapPendingStateDtoToDomain } from './mapPendingStateDtoToDomain';

export const mapHoldingDtoToDomain =
  (totalPortfolioValue: number, defaultPercentValue: number) =>
  (dto: HoldingDTO): HoldingEntity => {
    const marketValue = mapNumberDto(dto.market_value);

    const predefinedPercentOfPortfolio = mapNumberDto(dto.target_ratio);

    let percentOfAllPortfolio = 0;

    if (totalPortfolioValue !== 0) {
      if (marketValue !== 0) {
        percentOfAllPortfolio = (marketValue / totalPortfolioValue) * 100;
      }
    } else if (predefinedPercentOfPortfolio !== 0) {
      percentOfAllPortfolio = predefinedPercentOfPortfolio;
    } else {
      percentOfAllPortfolio = defaultPercentValue;
    }

    return {
      name: dto.name,
      ticker: dto.symbol,
      marketValue,
      quantity: mapNumberDto(dto.qty),
      performance: mapNumberDto(dto.performance),
      totalReturns: mapNumberDto(dto.returns),
      percentOfAllPortfolio,
      type: mapHoldingType(dto.type),
      industry: dto.industry,
      pendingState: mapPendingStateDtoToDomain(dto.pending_state),
      topics: dto.topics,
      isHighlighted: Boolean(dto.is_highlighted),
    };
  };

export const mapHoldingsDtoToDomain = (dto: HoldingDTO[]): HoldingEntity[] => {
  const totalValue = dto.reduce((acc, holdingItem) => acc + mapNumberDto(holdingItem.market_value), 0);

  /* If nothing was bought yet we just use 1/totalHoldingsLength as we use equal distribution when MIXED of
     PREMADE portfolios  */
  const defaultPercentValue = (1 / dto.length) * 100;

  return dto.map(mapHoldingDtoToDomain(totalValue, defaultPercentValue));
};
