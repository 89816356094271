import React, { useCallback } from 'react';
import { FormInstance } from 'antd';
import { useDispatch } from 'react-redux';
import * as O from 'fp-ts/Option';
import { isEmpty } from 'lodash';

import { validateACH } from '@services/transfers';

import { mapAccountType } from '../helpers';
import { EditACHValue } from '../api';

import { setEditACH } from '../api/stores';

export const usePrepareAchFromForm = (formRef: React.RefObject<FormInstance | undefined>) => {
  const dispatch = useDispatch();
  const form = formRef.current;

  return useCallback(
    (onSuccess: (editAchValue?: EditACHValue) => void, onError: (error: string) => void) => {
      if (form) {
        const { name } = form?.getFieldsValue() || {};
        if (!name) {
          onSuccess();
          return;
        }

        const newAch = {
          account_owner_name: form.getFieldValue('name'),
          account_type: form.getFieldValue('accountType'),
          account_number: form.getFieldValue('accountNumber'),
          routing_number: form.getFieldValue('accountRouting'),
        };

        const formValidation = validateACH(newAch);

        if (isEmpty(formValidation)) {
          const editAch: EditACHValue = O.some({
            type: 'manual',
            routing: newAch.routing_number,
            account: newAch.account_number,
            accountType: mapAccountType(newAch.account_type),
            owner: newAch.account_owner_name,
          });
          dispatch(setEditACH(editAch));
          onSuccess(editAch);
        } else {
          onError(`Please fill account info correctly`);
        }
      }
    },
    [form, dispatch],
  );
};
