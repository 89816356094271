import * as RD from '@devexperts/remote-data-ts';

import { mapApiError } from '@models/ApiError';

import { response } from '@api/helpers';
import { privateAPIv2 } from '~/api/base';
import { HoldingDTO, HoldingsEntityData } from '../../Holding.entity.types';
import { mapHoldingsDtoToDomain } from '../../helpers';

export const getHoldingsByPortfolio = (portfolioId: string) => {
  return async () => {
    return await response<HoldingDTO[]>(privateAPIv2.get(`/portfolios/${portfolioId}/holdings/`))
      .then(mapHoldingsDtoToDomain)
      .then<HoldingsEntityData>(RD.success)
      .catch((e): HoldingsEntityData => RD.failure(mapApiError(e)));
  };
};
