import { AxiosError } from 'axios';
import { getResponseErrorMessage } from '@api/helpers';

export type ApiError = {
  code: number;
  message: string;
};

export const mapApiError = (
  errorData: AxiosError | Error | any,
  defaultErrorMessage: string = 'Processing error, please try again',
): ApiError => {
  if ('isAxiosError' in errorData) {
    const status = Number(errorData.response?.status || errorData.code);
    const code = Number.isNaN(status) ? 0 : status;

    return {
      code,
      message: getResponseErrorMessage(errorData, defaultErrorMessage),
    };
  }

  if (errorData instanceof Error) {
    return {
      code: 0,
      message: errorData.message || defaultErrorMessage,
    };
  }

  return {
    code: 0,
    message: defaultErrorMessage,
  };
};
