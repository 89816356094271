import { Action } from 'redux';

import Types from './types';

import { isPayloadSafetyType } from '@store/util';

import { Profile } from '@models/profile';

export interface ProfileState {
  profileInfo: Profile | null;
  isLoading: boolean;
  error: string;
}

const initialState: ProfileState = {
  profileInfo: null,
  isLoading: false,
  error: '',
};

type CLEAR_PROFILE_ACTION = Action<Types.CLEAR_PROFILE>;
type MUTATE_PROFILE_STATE_ACTION = Action<Types.MUTATE_PROFILE_STATE> & { payload: Partial<Profile> };
type MODIFY_PROFILE_STATE_ACTION = Action<Types.MODIFY_PROFILE_STATE> & { payload: Profile };

type ProfileAction = CLEAR_PROFILE_ACTION | MUTATE_PROFILE_STATE_ACTION | MODIFY_PROFILE_STATE_ACTION;

export default function profileReducer(state: ProfileState = initialState, action: ProfileAction): ProfileState {
  if (action.type === Types.CLEAR_PROFILE) {
    return { ...state, profileInfo: null };
  }

  if (action.type === Types.MUTATE_PROFILE_STATE) {
    Object.keys(action.payload).forEach((_key) => {
      const key = _key as keyof Profile;
      if (state.profileInfo) {
        state.profileInfo = {
          ...state.profileInfo,
          [key]: action.payload[key],
        };
      }
      return state;
    });
  }

  if (action.type === Types.MODIFY_PROFILE_STATE) {
    return {
      ...state,
      profileInfo: {
        ...state.profileInfo,
        ...action.payload,
      },
    };
  }

  if (isPayloadSafetyType(action.type, Types)) {
    return { ...state, ...action.payload };
  }

  return state;
}
