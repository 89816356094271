import * as RD from '@devexperts/remote-data-ts';
import * as O from 'fp-ts/Option';

import { ACHRelationship } from '@models/transfers';
import { ApiError } from '@models/ApiError';

import { BankAccountType } from '~/entities/ach';

export type ACHAccountsValue = RD.RemoteData<ApiError, ACHRelationship[]>;

export enum MenuItem {
  Fund = 'Fund',
  Withdraw = 'Withdraw',
}

type ManualACHPayload = {
  type: 'manual';
  account: string;
  routing: string;
  accountType: BankAccountType;
  owner: string;
};

type PlaidACHPayload = {
  type: 'plaid';
  id?: string;
  token?: string;
  // balance?: number;
  account?: string;
  accountType: BankAccountType;
};

type EditBasedOnExistingAccounts = {
  type: 'saved-plaid' | 'platform' | 'saved-manual';
  id: string;
  lastDigits: string;
  accountType: BankAccountType;
  displayName: string;
};

type NewEditAccount = PlaidACHPayload | ManualACHPayload;

export type SetAchPayload = EditBasedOnExistingAccounts | NewEditAccount;

export type SetBankAccountParams = {
  portfolioId: string;
  payload: SetAchPayload;
};

export type EditACHValuePlain = SetBankAccountParams['payload'];

export type EditACHValue = O.Option<EditACHValuePlain>;
