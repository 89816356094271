import * as RD from '@devexperts/remote-data-ts';
import { constVoid } from 'fp-ts/function';

import portfolioAPI from '@api/endpoints/portfolio';
import { response } from '@api/helpers';
import { mapApiError } from '@models/ApiError';

import { mapPortfolioRequestToDTO } from '../helpers';
import { PortfolioListItem, CreatePortfolioParams } from '../portfolio.types';
import { mapPortfolioItemDto } from '../list';

export const createPortfolio = (
  params: CreatePortfolioParams,
  onSuccess: (newPortfolio: PortfolioListItem) => void = constVoid,
) =>
  response(portfolioAPI.createPortfolio(mapPortfolioRequestToDTO(params)))
    .then(mapPortfolioItemDto)
    .then((portfolioListItem) => {
      onSuccess(portfolioListItem);
      return portfolioListItem;
    })
    .then(RD.success)
    .catch((e) => RD.failure(mapApiError(e)));
