import { spawn } from 'redux-saga/effects';
import { combineReducers } from 'redux';

import { watchPendingTransfersSaga, PendingReducer, loadPendingTransfersInfoAction } from './pendging';

export const TransfersReducer = combineReducers({
  pending: PendingReducer,
});

export const transfersActions = {
  pending: {
    load: loadPendingTransfersInfoAction,
  },
};
export function* watchTransfersSaga() {
  yield spawn(watchPendingTransfersSaga);
}
