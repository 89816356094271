import * as RD from '@devexperts/remote-data-ts';
import * as A from 'fp-ts/Array';

import { response } from '@api/helpers';
import { mapApiError } from '@models/ApiError';
import { privateAPIv2 } from '~/api/base';

import { PendingItemDTO, PendingItemsData } from '../../pendging';
import { mapPendingTransferDtoToDomain } from '../../helpers';

export const getPendingTransfers = {
  getPendingTransfersByPortfolioId: (portfolioId: string): Promise<PendingItemsData> =>
    response(privateAPIv2.get<PendingItemDTO[]>(`/transfers/pending/?portfolio_id=${portfolioId}`))
      .then(A.map(mapPendingTransferDtoToDomain))
      .then(RD.success)
      .catch((e) => RD.failure(mapApiError(e))),
  getAllPendingTransfers: (): Promise<PendingItemsData> =>
    response(privateAPIv2.get<PendingItemDTO[]>(`/transfers/pending/`))
      .then(A.map(mapPendingTransferDtoToDomain))
      .then(RD.success)
      .catch((e) => RD.failure(mapApiError(e))),
};
