import styled from 'styled-components';

import { Button } from '~/ui/kit/atoms/Button';
import { fontSize } from '~/ui/kit/constants/fonts';
import { getEkoBorderRadius } from '~/ui/kit/constants/sizes';
import { Card } from '~/ui/kit/organisms/Card';

export const Container = styled(Card).attrs({
  $fs: 'xs',
})`
  background: var(--eko-primary-color-light-6);
  border-radius: ${getEkoBorderRadius({ $br: 'm' })};
  ${fontSize};
`;

export const RetryButton = styled(Button).attrs({
  type: 'link',
})`
  line-height: inherit;
  font-weight: bold;
`;
