import { useCallback, useState } from 'react';
import * as RD from '@devexperts/remote-data-ts';

import { RemoveHoldingParams, removeHoldingApi, RemoveHoldingResult } from '../../api';

export const useRemoveHolding = (onSuccess?: VoidFunction) => {
  const [state, setState] = useState<RemoveHoldingResult>(RD.initial);

  const removeHolding = useCallback(
    (params: RemoveHoldingParams) => {
      setState(RD.pending);
      removeHoldingApi(params, onSuccess).then(setState);
    },
    [onSuccess],
  );

  const cleanUp = useCallback(() => {
    setState(RD.initial);
  }, []);

  return { state, removeHolding, cleanUp };
};
