import { DefaultRootState } from 'react-redux';
import * as RD from '@devexperts/remote-data-ts';
import { PlaidLinkOnSuccessMetadata } from 'react-plaid-link';
import { Action } from 'redux';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { ACHRelationship } from '@models/transfers';
import { mapApiError } from '@models/ApiError';

import { getBankAccount } from '../getBankAccount.api';

import { BankAccountData, BankAccountDTO, BankAccount } from '../../types';
import { EditACHValuePlain } from '../types';

import { setBankAccount, disconnectBankAccount } from '../../api';
import { mapBankAccountTypeDTO } from '../../helpers/mapBankAccountDto.mapper';

const initialState: BankAccountData = RD.initial;

const SET = 'SET_ACH_STATE';

const FETCH = 'FETCH_ACH';

const REMOVE = 'REMOVE_ACH';

const UPDATE = 'UPDATE_ACH';

const UPDATE_PLAID = 'UPDATE_ACH_PLAID';

type SetAction = Action<typeof SET> & {
  payload: typeof initialState;
};

type ACHAction = SetAction;

export const ACHReducer = (state = initialState, action: ACHAction) => {
  switch (action.type) {
    case SET: {
      return action.payload;
    }

    default:
      return state;
  }
};

export const setACH = (payload: BankAccountData) => ({
  type: SET,
  payload: payload,
});

type LoadPortfolioACHAction = Action<typeof FETCH> & {
  payload: {
    portfolioId: string;
  };
};

const loadPortfolioACHAction = (portfolioId: string): LoadPortfolioACHAction => ({
  type: FETCH,
  payload: { portfolioId },
});

export function* loadACH(action: LoadPortfolioACHAction) {
  yield put(setACH(RD.pending));
  const res: BankAccountData = yield call(() => getBankAccount(action.payload.portfolioId));
  yield put(setACH(res));
}

type RemoveAction = Action<typeof REMOVE> & {
  payload: { bankAccount: BankAccount; portfolioId: string };
};

export function* removeAch(action: RemoveAction) {
  yield put(setACH(RD.pending));
  try {
    yield call(() => disconnectBankAccount(action.payload.portfolioId, action.payload.bankAccount));
  } catch (e) {
    console.error(e);
  }

  yield call(loadACH, loadPortfolioACHAction(action.payload.portfolioId));
}

type UpdateAction = Action<typeof UPDATE> & {
  payload: {
    portfolioId: string;
    ach: EditACHValuePlain;
    onSuccess?: () => void;
    onError?: (e: string) => void;
  };
};

type UpdatePlaidAction = Action<typeof UPDATE_PLAID> & {
  payload: {
    portfolioId: string;
    publicToken: string;
    meta: PlaidLinkOnSuccessMetadata;
    onError?: (e: string) => void;
    onSuccess?: VoidFunction;
    fallbackValue?: RD.RemoteData<Error, ACHRelationship>;
  };
};

export const setPlaidACHAction = (
  portfolioId: string,
  publicToken: string,
  meta: PlaidLinkOnSuccessMetadata,
  onSuccess?: () => void,
  onError?: (e: string) => void,
  fallbackValue?: RD.RemoteData<Error, ACHRelationship>,
): UpdatePlaidAction => ({
  type: UPDATE_PLAID,
  payload: {
    portfolioId,
    publicToken,
    meta,
    onError,
    onSuccess,
    fallbackValue,
  },
});

export const setACHAction = (
  portfolioId: string,
  ach: EditACHValuePlain,
  onSuccess?: VoidFunction,
  onError?: (e: string) => void,
): UpdateAction => ({
  type: UPDATE,
  payload: {
    ach,
    portfolioId,
    onSuccess,
    onError,
  },
});

export function* setACHByPlaidToken(action: UpdatePlaidAction) {
  const store: DefaultRootState = yield select();
  yield put(setACH(RD.pending));

  const {
    payload: { publicToken, meta, onError, portfolioId, onSuccess },
  } = action;

  try {
    const newAch = {
      public_token: publicToken,
      account_id: meta.accounts[0].id,
      account_name: meta.accounts[0].name,
      account_mask: meta.accounts[0].mask,
      type: meta.accounts[0].subtype, // checking | savings
      institution_id: meta.institution?.institution_id,
    };

    const res: BankAccountData = yield setBankAccount(portfolioId, {
      type: 'plaid',
      account: newAch.account_id,
      token: newAch.public_token,
      accountType: mapBankAccountTypeDTO(newAch.type as BankAccountDTO['type']),
    });
    if (RD.isFailure(res)) {
      yield put(setACH(store.ACH.profile));
      void (onError && onError(res.error.message));
    } else {
      if (onSuccess) {
        onSuccess();
      }
      yield call(loadACH, loadPortfolioACHAction(portfolioId));
    }
  } catch (e) {
    yield put(setACH(store.ACH.profile));
    void (onError && onError(mapApiError(e).message));
  }
}

export function* updateACH(action: UpdateAction) {
  const store: DefaultRootState = yield select();
  const prevValue = store.ACH.profile;

  yield put(setACH(RD.pending));
  const {
    payload: { ach: newRelationship, onSuccess, onError, portfolioId },
  } = action;

  try {
    const res: BankAccountData = yield call(() => setBankAccount(portfolioId, newRelationship));

    if (RD.isFailure(res)) {
      yield put(setACH(prevValue));
      void (onError && onError(res.error.message));
    } else {
      yield call(loadACH, loadPortfolioACHAction(portfolioId));
      void (onSuccess && onSuccess());
    }
  } catch (e) {
    void (onError && onError(mapApiError(e).message));
    yield call(loadACH, loadPortfolioACHAction(portfolioId));
  }
}

export function* watchACHSaga() {
  yield takeLatest(FETCH, loadACH);
  yield takeLatest(UPDATE, updateACH);
  yield takeLatest(REMOVE, removeAch);
  yield takeLatest(UPDATE_PLAID, setACHByPlaidToken);
}

export { loadPortfolioACHAction as loadACHAction };

export const removeACHAction = (portfolioId: string, bankAccount: BankAccount): RemoveAction => ({
  type: REMOVE,
  payload: { portfolioId, bankAccount },
});
