import * as RD from '@devexperts/remote-data-ts';
import * as A from 'fp-ts/Array';

import { response } from '@api/helpers';
import { mapApiError } from '@models/ApiError';
import { privateAPIv2 } from '~/api/base';

import { BankAccountDTO, ConnectedBankAccountsData, BankAccount } from '../types';
import { mapBankAccountDto } from '../helpers/mapBankAccountDto.mapper';
import { constVoid } from 'fp-ts/function';

export const getConnectedBankAccounts = (
  onSuccess: (accounts: BankAccount[]) => void = constVoid,
): Promise<ConnectedBankAccountsData> => {
  return response(privateAPIv2.get<BankAccountDTO[]>('/bank-accounts/'))
    .then(A.map(mapBankAccountDto))
    .then((accounts) => {
      onSuccess(accounts);
      return accounts;
    })
    .then(RD.success)
    .catch((e) => RD.failure(mapApiError(e)));
};
