import AuthTypes, { LoginTypes } from './types';

import { AuthData } from '@models/auth';

import * as builder from '@store/builder';

export type LoginAction = {
  type: AuthTypes.LOGIN;
  payload: AuthData;
};

export type LogoutAction = {
  type: AuthTypes.LOGOUT;
};

export const login = (authData: AuthData): LoginAction => ({
  type: AuthTypes.LOGIN,
  payload: authData,
});

export const loginStarted = builder.buildStartedAction(LoginTypes.STARTED);
export const loginReset = builder.buildSimpleResetAction(LoginTypes.RESET);
export const loginSucceeded = builder.buildSimpleSucceededAction(LoginTypes.SUCCEEDED);
export const loginFailed = builder.buildFailedAction(LoginTypes.FAILED);

export const logout = (): LogoutAction => ({
  type: AuthTypes.LOGOUT,
});
