import { ASSET_TYPE, AssetType, AssetDTO } from '../../asset.types';

export const mapAssetType = (value: AssetDTO['type']): AssetType => {
  switch (value) {
    case 'STOCK':
      return ASSET_TYPE.STOCK;
    case 'BOND':
      return ASSET_TYPE.BOND;
    case 'ETF':
      return ASSET_TYPE.ETF;
  }

  // TODO find out what is default
  return ASSET_TYPE.STOCK;
};
