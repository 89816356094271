import { useMemo } from 'react';
import * as RD from '@devexperts/remote-data-ts';
import { pipe } from 'fp-ts/function';

import { PortfolioListItem } from '../../portfolio.types';
import { useActivePortfolioInfo } from '../useActivePortfolioInfo';

const RISK_LEVELS = [
  {
    label: 'low-risk',
    percents: '5-6',
  },
  {
    label: 'low/medium-risk',
    percents: '5-7',
  },
  {
    label: 'medium-risk',
    percents: '6-8',
  },
  {
    label: 'medium-opportunistic risk',
    percents: '5-9',
  },
  {
    label: 'opportunistic-risk',
    percents: '4-10',
  },
];

const getLabel = ({ riskLevel: riskLevelIndex }: PortfolioListItem) =>
  RISK_LEVELS[riskLevelIndex]
    ? `Your ${RISK_LEVELS[riskLevelIndex].label} portfolio is designed to yield an average annual return of 
  ${RISK_LEVELS[riskLevelIndex].percents}%.`
    : '';

export const useRiskLevelLabel = () => {
  const [activePortfolioInfo] = useActivePortfolioInfo();

  return useMemo(
    () =>
      pipe(
        activePortfolioInfo,
        RD.map(getLabel),
        RD.getOrElse(() => ''),
      ),
    [activePortfolioInfo],
  );
};
