import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as RD from '@devexperts/remote-data-ts';
import { constVoid } from 'fp-ts/function';

import { ApiError } from '@models/ApiError';
import { updatePortfolioItem } from '../../list';
import { editPortfolioProperty, EditPortfolioPropertyParams } from '../../api';
import { PortfolioListItem } from '../../portfolio.types';

export { type EditPortfolioPropertyParams } from '../../api';

export const useEditPortfolioProperty = (onSuccess: (updatedPortfolio: PortfolioListItem) => void = constVoid) => {
  const dispatch = useDispatch();
  const [result, setResult] = useState<RD.RemoteData<ApiError, PortfolioListItem>>(RD.initial);

  const handleSuccess: typeof onSuccess = useCallback(
    (item) => {
      dispatch(updatePortfolioItem(item));
      onSuccess(item);
    },
    [onSuccess, dispatch],
  );

  const update = useCallback(
    (params: EditPortfolioPropertyParams) => {
      setResult(RD.pending);

      editPortfolioProperty(params, handleSuccess).then(setResult);
    },
    [handleSuccess],
  );

  const resetResults = useCallback(() => {
    setResult(RD.initial);
  }, []);

  return [result, update, resetResults] as const;
};
