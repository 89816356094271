import { DefaultRootState } from 'react-redux';
import { createSelector } from 'reselect';

import { PendingItemsData } from './pending-transfers.types';

export const getPendingTransfersSelector = <
  TransfersSelector extends (state: DefaultRootState) => { pending: PendingItemsData },
>(
  transfersSelector: TransfersSelector,
) => createSelector(transfersSelector, ({ pending }) => pending);
