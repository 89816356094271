import { DefaultRootState } from 'react-redux';
import { createSelector } from 'reselect';

import { OrganizationState } from '@store/organization';

export const organizationSelector = (state: DefaultRootState) => state.Organization;

export const selectOrganization = createSelector(organizationSelector, (Organization) => Organization.organizationInfo);

export const selectIsOrganizationLoading = createSelector(
  organizationSelector,
  (Organization: OrganizationState) => Organization.isLoading,
);

export const selectOrganizationError = createSelector(
  organizationSelector,
  (Organization: OrganizationState) => Organization.error,
);
