import { differenceInDays } from 'date-fns';

import { BankAccount } from '~/entities/ach';

export const SWITCH_DISABLED_BY_RECURRING = 'You cannot change bank account while recurring deposit is in progress.';

export const SWITCH_DISABLED_BY_PENDING_DEPOSIT = 'You cannot change your bank account while a deposit is pending.';

export const SWITCH_DISABLED_BY_PENDING_WITHDRAWAL =
  'You cannot change your account while there is pending a withdrawal in progress.';

const DAYS_TO_GET_TRUSTED = 60;

export const getWithdrawLockedReasonByTrustedPeriod = (account: BankAccount): string | undefined => {
  if (!account.createdAt) {
    return;
  }

  const createdAt = account.createdAt;
  const daysSinceCreation = differenceInDays(new Date(), createdAt);
  const daysLeftToBeTrusted = DAYS_TO_GET_TRUSTED - daysSinceCreation;

  if (daysLeftToBeTrusted < 1) {
    return;
  }

  return (
    `You are not able to switch your withdrawal account within 60 days of the initial connection. ` +
    `You will be able to change it in ${daysLeftToBeTrusted} days`
  );
};
