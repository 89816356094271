import * as RD from '@devexperts/remote-data-ts';

import { privateAPIv2 } from '~/api/base';
import { mapApiError } from '@models/ApiError';
import { response } from '@api/helpers';

import { AssetDTO, AssetData } from '../../asset.types';
import { mapAssetDtoToDomain } from '../../helpers';

const defaultParams: object = { topics_category: `social` };
export const getAsset = async (ticker: string, params = defaultParams) => {
  return await response(privateAPIv2.get<AssetDTO>(`/assets/${ticker}/`, { params }))
    .then(mapAssetDtoToDomain)
    .then<AssetData>(RD.success)
    .catch((e): AssetData => RD.failure(mapApiError(e)));
};
