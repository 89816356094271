import * as RD from '@devexperts/remote-data-ts';

import { mapApiError } from '@models/ApiError';
import { response } from '@api/helpers';
import { privateAPIv2 } from '~/api/base';

import { BankAccountDTO } from '../types';
import { mapBankAccountDto } from '../helpers/mapBankAccountDto.mapper';

export const getBankAccount = (portfolioId: string) => {
  return response(privateAPIv2.get<BankAccountDTO>(`/portfolios/${portfolioId}/bank-account/`))
    .then(mapBankAccountDto)
    .then(RD.success)
    .catch((e) => RD.failure(mapApiError(e)));
};
