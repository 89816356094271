import * as builder from '@store/builder';

const initialState: string = '';

export const actions = builder.getModuleActions('ActivePortfolio');

export const setAction = (payload: string) => {
  return {
    type: actions.SET,
    payload,
  };
};

type ActivePortfolioAction = ReturnType<typeof setAction>;

export const ActivePortfolioReducer = (state = initialState, action: ActivePortfolioAction) => {
  switch (action.type) {
    case actions.SET: {
      return action.payload;
    }
  }

  return state;
};
