import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as RD from '@devexperts/remote-data-ts';
import { constVoid } from 'fp-ts/function';

import { ApiError } from '@models/ApiError';

import { deletePortfolio, DeletePortfolioDestinationParams } from '../../api';
import { deletePortfolioItemAction } from '../../list';

export const useDeletePortfolio = (onSuccess: (id: string) => void = constVoid) => {
  const dispatch = useDispatch();
  const [result, setResult] = useState<RD.RemoteData<ApiError, string>>(RD.initial);

  const handleSuccess: typeof onSuccess = useCallback(
    (id: string) => {
      onSuccess(id);
    },
    [onSuccess],
  );

  const remove = useCallback(
    (portfolioId: string, destination: DeletePortfolioDestinationParams) => {
      setResult(RD.pending);

      deletePortfolio({ portfolioId, ...destination }, handleSuccess).then(setResult);
    },
    [handleSuccess],
  );

  const updateListState = useCallback(
    (portfolioId: string) => {
      dispatch(deletePortfolioItemAction(portfolioId));
    },
    [dispatch],
  );

  return [result, remove, updateListState] as const;
};
