import { HOLDING_TYPE, HoldingType, HoldingDTO } from '../Holding.entity.types';

export const mapHoldingType = (value: HoldingDTO['type']): HoldingType => {
  switch (value) {
    case 'STOCK':
      return HOLDING_TYPE.STOCK;
    case 'BOND':
      return HOLDING_TYPE.BOND;
    case 'ETF':
      return HOLDING_TYPE.ETF;
  }

  // TODO find out what is default
  return HOLDING_TYPE.STOCK;
};
