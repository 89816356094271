import * as RD from '@devexperts/remote-data-ts';
import { ApiError } from '@models/ApiError';

export const HOLDING_TYPE = {
  ETF: 'ETF',
  BOND: 'Bond-ETF',
  STOCK: 'Stock',
} as const;

type HoldingTypeKey = keyof typeof HOLDING_TYPE;
export type HoldingType = (typeof HOLDING_TYPE)[HoldingTypeKey];

export type PendingStateDTO = 'REMOVAL' | 'ADDITION' | 'BUYING' | 'SELLING';

export type PendingState = 'removal' | 'addition' | 'buying' | 'selling';

export type HoldingDTO = {
  type: 'ETF' | 'STOCK' | 'BOND';
  name: string;
  symbol: string;
  market_value: number;
  qty: number;
  performance: number;
  returns: string;
  industry: string;
  topics: number[];
  pending_state?: PendingStateDTO;
  is_highlighted?: boolean;
  target_ratio?: string;
};

export type HoldingEntity = {
  type: HoldingType;
  name: string;
  ticker: string;
  marketValue: number;
  quantity: number;
  performance: number;
  percentOfAllPortfolio: number;
  totalReturns: number;
  industry: string;
  pendingState?: PendingState;
  topics: number[];
  isHighlighted: boolean;
};

export type HoldingsEntityData = RD.RemoteData<ApiError, HoldingEntity[]>;
