import { DefaultRootState } from 'react-redux';

import { getPendingTransfersSelector } from './pendging';

const transfersSelector = (state: DefaultRootState) => {
  return state.Transfers;
};

const pendingSelector = getPendingTransfersSelector(transfersSelector);

export const transfersSelectors = {
  pending: pendingSelector,
};
