import { privateAPI } from '~/api/base';
import { UserSettings } from '@store/settings';
import { mapApiError } from '@models/ApiError';

export const userApi = {
  getUserMetaInfo: () =>
    privateAPI.get<UserSettings>('/user/meta/').catch((e) => {
      throw mapApiError(e);
    }),
  setUserMetaInfo: (newValue: Partial<UserSettings>) => privateAPI.patch<UserSettings>('/user/meta/', newValue),
};
