import createSagaMiddleware from 'redux-saga';
import { call, fork } from 'redux-saga/effects';

import applicationSaga from '@store/application/sagas';
import authSaga from '@store/authentication/sagas';

const sagaMiddleware = createSagaMiddleware();

export function* rootSaga() {
  try {
    yield fork(applicationSaga);
  } catch (e) {
    console.error('Application:', e);
  }
  yield call(authSaga);
}

export const init = () => sagaMiddleware.run(rootSaga);

export default sagaMiddleware;
