import * as RD from '@devexperts/remote-data-ts';
import { ApiError } from '@models/ApiError';

export const PORTFOLIO_RISK_LEVEL = {
  0: 0,
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
} as const;

export type PortfolioRiskLevelValue = keyof typeof PORTFOLIO_RISK_LEVEL;

export const PORTFOLIO_TYPE_DTO = {
  SELF_DIRECT: 'SELF_DIRECT',
  MIXED: 'MIXED',
  GUIDED: 'GUIDED',
} as const;

export type PortfolioTypeDtoValue = keyof typeof PORTFOLIO_TYPE_DTO;

export const PORTFOLIO_TYPE = {
  SELF_DIRECT: 'SELF_DIRECT',
  MIXED: 'MIXED',
  PRE_MADE: 'PRE_MADE',
} as const;

export type PortfolioTypeKey = keyof typeof PORTFOLIO_TYPE;

export type PortfolioTypeValue = (typeof PORTFOLIO_TYPE)[PortfolioTypeKey];

export enum FirstDepositStatus {
  NOT_INITIATED = 'NOT_INITIATED',
  INITIATED = 'INITIATED',
  RECEIVED = 'RECEIVED',
  PROCESSED = 'PROCESSED',
}

export type PortfolioListItem = {
  name: string;
  id: string;
  equity: number;
  cash: number;
  reservedCash: number;
  topics: number[];
  portfolioType: PortfolioTypeValue;
  riskLevel: PortfolioRiskLevelValue;
  prebuilt_id?: string;
  firstDepositStatus: FirstDepositStatus;
  highlightedTicker?: string;
  infoBullets: string[];
  createdAt?: Date;
  performance?: number;
};

export type PortfolioItemDTO = {
  name: string;
  id: string;
  equity: string | number;
  cash: string | number;
  cash_reserved: string | number;
  topics: number[];
  portfolio_type: PortfolioTypeDtoValue;
  risk_level: PortfolioRiskLevelValue;
  first_deposit_status: string;
  prebuilt_id?: string | null;
  highlighted_symbol?: string | null;
  info_bullets?: string[] | null;
  created_at: string;
  investment_reason?: string | null;
  bank_account_id?: string | null;
  performance?: string;
};

export type CreatePortfolioParams = {
  name: string;
  portfolioType: PortfolioTypeValue;
  risk?: number;
  premadeId?: string;
  topics?: number[];
  amount: number;
  reason: string;
};

export type CreatePortfolioDTORequest = {
  name: string;
  topics: number[];
  portfolio_type: PortfolioTypeDtoValue;
  prebuilt_id: string;
  risk_level: number;
  investment_reason: string;
  investment_amount: number;
};

export type PortfolioListItemData = RD.RemoteData<ApiError, PortfolioListItem>;
