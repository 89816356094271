import { DefaultRootState } from 'react-redux';
import { createSelector } from 'reselect';
import { pipe } from 'fp-ts/function';
import * as RD from '@devexperts/remote-data-ts';

export const portfolioListSelector = (state: DefaultRootState) => state.Portfolio.list;

export const portfolioListCounterSelector = createSelector(portfolioListSelector, (portfolioList): number =>
  pipe(
    portfolioList,
    RD.map((list) => list.length),
    RD.getOrElse(() => 0),
  ),
);
