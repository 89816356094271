import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as F from 'fp-ts/function';
import * as RD from '@devexperts/remote-data-ts';

import { achSelector } from '../../api/selectors';
import {
  getWithdrawLockedReasonByTrustedPeriod,
  SWITCH_DISABLED_BY_PENDING_WITHDRAWAL,
  SWITCH_DISABLED_BY_RECURRING,
} from '../../helpers/constants';

type Params = {
  isRecurringAvailable: boolean;
  hasPendingWithdrawal: boolean;
};

export const useWithdrawLockReason = (params: Params) => {
  const achRD = useSelector(achSelector);

  return useMemo(() => {
    const lockedByPeriodReason = F.pipe(achRD, RD.map(getWithdrawLockedReasonByTrustedPeriod), RD.toNullable);

    if (lockedByPeriodReason) {
      return lockedByPeriodReason;
    }

    if (!params.isRecurringAvailable) {
      return SWITCH_DISABLED_BY_RECURRING;
    }

    if (params.hasPendingWithdrawal) {
      return SWITCH_DISABLED_BY_PENDING_WITHDRAWAL;
    }

    return null;
  }, [achRD, params.isRecurringAvailable, params.hasPendingWithdrawal]);
};
