import { call, put, takeLatest } from 'redux-saga/effects';
import * as RD from '@devexperts/remote-data-ts';

import { AlpacaAccountInfoDTO } from 'src/data/api/schemas/broker';
import { response } from '@api/helpers';

import { AlpacaAccountState } from './alpaca_account.types';
import * as builder from '../builder';
import brokerAPI from '../../api/endpoints/broker';

const initialState: AlpacaAccountState = RD.initial;

const action = builder.getModuleAction('ALPACA_ACCOUNT');

const SET = action('SET');

const FETCH = action('FETCH');

export const setAlpacaAccount = (payload: AlpacaAccountState) => ({
  type: SET,
  payload: payload,
});

type AlpacaAccountAction = ReturnType<typeof setAlpacaAccount>;

export const AlpacaAccountReducer = (state = initialState, action: AlpacaAccountAction) => {
  switch (action.type) {
    case SET: {
      return action.payload;
    }
    default:
      return state;
  }
};

export const loadAlpacaAccountAction = builder.buildRequestAction(FETCH);

export function* loadAlpacaAccount() {
  yield put(setAlpacaAccount(RD.pending));
  try {
    const account: AlpacaAccountInfoDTO = yield call(response, brokerAPI.fetchAlpacaAccount());
    yield put(setAlpacaAccount(account ? RD.success(account) : RD.initial));
  } catch (e) {
    yield put(setAlpacaAccount(RD.failure(e as unknown as Error)));
  }
}

export function* watchAlpacaAccountSaga() {
  yield takeLatest(FETCH, loadAlpacaAccount);
}
