import { constVoid } from 'fp-ts/function';
import * as RD from '@devexperts/remote-data-ts';

import { privateAPIv2 } from '~/api/base';
import { response } from '@api/helpers';
import { mapApiError } from '@models/ApiError';

export type DeletePortfolioDestinationParams =
  | {
      type: 'bank-accounts' | 'portfolio';
      id: string;
    }
  | {
      type: 'zero-balance';
    };

type DeletePortfolioParams = { portfolioId: string } & DeletePortfolioDestinationParams;

export const deletePortfolio = (params: DeletePortfolioParams, onSuccess: (id: string) => void = constVoid) => {
  // return sleep(2000).then(() => params.portfolioId).then((portfolioId) => {
  //   onSuccess(portfolioId);
  //   return RD.success(portfolioId);
  // });
  return response<{ id: string }>(privateAPIv2.delete(`/portfolios/${params.portfolioId}/`))
    .then(({ id }) => {
      onSuccess(id);

      return id;
    })
    .then(RD.success)
    .catch((e) => RD.failure(mapApiError(e)));
};
