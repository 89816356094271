import { DefaultRootState } from 'react-redux';
import { createSelector } from 'reselect';

const selectApp = (state: DefaultRootState) => state.Application;
const selectCustomization = createSelector(selectApp, (App) => App.customization);

export const selectIsApplicationLoading = createSelector(selectApp, (App) => App.isLoading);

export const selectIsExternalUser = createSelector(selectApp, (App) => App.isExternal);

export const selectIsApplicationError = createSelector(selectApp, (App) => !!App.error);

export const selectApplicationError = createSelector(selectApp, (App) => App.error);

// todo remove
export const selectMenuType = createSelector(selectCustomization, (customization) => customization.menuType);

export * from './notifications/selectors';
