import * as appTypes from '~/types/types';
import { ACH_SELECTION_TYPES } from '@constants/accounts';
import { PAGE_TITLE } from '@constants/constants';

export const appInitialState = {
  title: PAGE_TITLE,
  visual: {
    styles: '',
    logo: '',
    displayName: '',
    isEmojiOn: true,
  },
  behaviour: {
    achSelectionType: ACH_SELECTION_TYPES.PLAID,
    passwordChangeAvailable: true,
  },
};

export const appReducer = (state, { type, payload }) => {
  switch (type) {
    case appTypes.SET_TITLE:
      return { ...state, title: payload };

    case appTypes.SET_STYLES:
      return { ...state, visual: { ...state.visual, styles: payload } };

    case appTypes.SET_LOGO:
      return { ...state, visual: { ...state.visual, logo: payload } };

    case appTypes.SET_DISPLAY_NAME:
      return { ...state, visual: { ...state.visual, displayName: payload } };

    case appTypes.SET_EMOJI_ON:
      return { ...state, visual: { ...state.visual, isEmojiOn: payload } };

    case appTypes.SET_PASSWORD_CHANGE_AVAILABILITY:
      return { ...state, behaviour: { ...state.behaviour, passwordChangeAvailable: payload } };

    case appTypes.SET_SELECTION_TYPE:
      return { ...state, behaviour: { ...state.behaviour, achSelectionType: payload } };
  }
};
