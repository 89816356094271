import { DefaultRootState } from 'react-redux';
import { createSelector } from 'reselect';

import { ProfileState } from '@store/profile';
import * as local from '@store/profile/local';

import { Profile } from '@models/profile';

import { retrieve } from '@utils/persistence';

export const profileSelector = (state: DefaultRootState) => state.Profile;

export const selectProfile = createSelector(
  profileSelector,
  (Profile: ProfileState): Profile | null => Profile.profileInfo,
);

export const selectIsProfileLoading = createSelector(
  profileSelector,
  (Profile: ProfileState): boolean => Profile.isLoading,
);

export const getHasAlpacaAccount = (): boolean => retrieve(local.HAS_ALPACA_ACCOUNT_FIELD) === 'true';
export const getIsAccountClosure = (): boolean => retrieve(local.ACCOUNT_CLOSURE_FIELD) === 'true';
