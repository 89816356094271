import { DefaultRootState } from 'react-redux';
import { createSelector } from 'reselect';
import { pipe } from 'fp-ts/function';
import * as RD from '@devexperts/remote-data-ts';
import * as A from 'fp-ts/Array';

import { checkById } from '@utils/compare';
import { fromOption } from '@utils/RemoteData';

const selectPortfolio = (state: DefaultRootState) => state.Portfolio;

export const selectActivePortfolioInfo = createSelector(selectPortfolio, ({ list, active }) =>
  pipe(
    list,
    RD.map(A.findFirst(checkById(active))),
    RD.chain((data) => (!active ? RD.pending : RD.success(data))),
    RD.chain(
      fromOption(() =>
        RD.failure({
          code: 404,
          message: 'No portfolio found with current id',
        }),
      ),
    ),
  ),
);
