import { useCallback } from 'react';
import * as RD from '@devexperts/remote-data-ts';

import { getPortfolioById } from '../../api';
import { useChangeLocalPortfolioValue } from '../useChangeLocalPortfolioValue';

export const useShadowUpdatePortfolioById = () => {
  const changeLocalPortfolioValue = useChangeLocalPortfolioValue;

  return useCallback(
    (portfolioId: string) => {
      getPortfolioById(portfolioId).then(RD.map(changeLocalPortfolioValue));
    },
    [changeLocalPortfolioValue],
  );
};
