import styled, { createGlobalStyle, css } from 'styled-components';
import { Input } from '~/ui/kit/molecules/Input';
import { baseFont, fontSize } from '~/ui/kit/constants/fonts';
import { SelectDropdown, selectItemPaddings } from '~/ui/kit/molecules/Input/Select';
import { Button } from '~/ui/kit/atoms/Button';
import { IconCross } from '~/ui/kit/atoms/IconCross';
import { gaps } from '~/ui/kit/constants/sizes';

import { PortfolioSelectItem } from './ui/PortfolioSelectItem';

export const DROPDOWN_CLASSNAME = 'portfolio-selector__dropdown';

export const DropdownStyles = createGlobalStyle<{ $isMobile: boolean; $leftPosition: number }>`
  .${DROPDOWN_CLASSNAME} {
    z-index: 999999;
    ${({ $isMobile, $leftPosition }) =>
      $isMobile
        ? css`
            width: calc(100% - ${$leftPosition * 2}px);
          `
        : css`
            max-width: 400px;
          `} ;
    
    ${SelectDropdown} {
      --eko-select-active-background-color: unset;
      --eko-select-active-color: var(--eko-primary-color);
      --eko-select-hover-background-color: unset;
      border: unset;
      color: var(--eko-primary-color);
    }
  }
`;

export const Container = styled(Input.Select).attrs({
  dropdownMatchSelectWidth: false,
  isTextLabelDropdown: true,
  placement: 'bottomLeft',
  fullWidth: true,
  listHeight: 275,
})`
  color: var(--eko-primary-color);
  ${fontSize};
  font-weight: bold;
`;

export const Item = styled(PortfolioSelectItem)``;

const elementPaddings = css`
  ${selectItemPaddings};
`;

export const Before = styled.div.attrs({ $fs: 's' })`
  ${elementPaddings};
  ${fontSize};
  ${baseFont};
  color: var(--eko-third-color);
`;

export const After = styled(Button).attrs({
  type: 'link',
})`
  &.ant-btn {
    display: flex;
    align-items: center;
    gap: ${gaps.xxs}px;
    width: 100%;
    min-width: 300px;
    ${elementPaddings};
    text-transform: capitalize;
    ${fontSize};
    ${baseFont};
    font-weight: bold;

    & span {
      text-decoration: none;
    }
  }
`;

export const AddIcon = styled(IconCross)`
  transform: rotateZ(45deg) scale(0.71);
  height: 20px;
  width: 20px;
`;
