import { useCallback, useState } from 'react';
import * as RD from '@devexperts/remote-data-ts';

import { ApiError } from '@models/ApiError';
import { PortfolioListItem } from '~/entities/protfolio';

import { CreatePortfolioParams } from '../../portfolio.types';
import { createPortfolio } from '../../api';

export const useCreatePortfolio = (onSuccess?: (newPortfolio: PortfolioListItem) => void) => {
  const [state, setState] = useState<RD.RemoteData<ApiError, PortfolioListItem>>(RD.initial);

  const create = useCallback(
    (params: CreatePortfolioParams) => {
      setState(RD.pending);
      createPortfolio(params, onSuccess).then(setState);
    },
    [onSuccess],
  );

  const reset = useCallback(() => {
    setState(RD.initial);
  }, []);

  return [state, create, reset] as const;
};
