import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import * as F from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import * as RD from '@devexperts/remote-data-ts';

import { getLastDigits } from '@utils/getLastDigits.helper';
import { ACHRelationship } from '@models/transfers';

import { editACHSelector, achSelector, achAccountsSelector } from '../api/selectors';
import { pipe } from 'fp-ts/function';
import { BankAccount } from '~/entities/ach';

export const useActiveACHRelationship = (): O.Option<BankAccount> => {
  const editACH = useSelector(editACHSelector);
  const achRD = useSelector(achSelector);
  const accountsPlainRD = useSelector(achAccountsSelector);
  const accountsPlain = useMemo(
    () =>
      F.pipe(
        accountsPlainRD,
        RD.getOrElse((): ACHRelationship[] => []),
      ),
    [accountsPlainRD],
  );

  return useMemo(
    () =>
      F.pipe(
        editACH,
        O.map((editValue) => {
          if (editValue.type === 'manual') {
            return {
              id: 'manual-id',
              displayName: editValue.type as any,
              type: editValue.accountType,
              account: editValue.account,
              routing: editValue.routing,
              balance: undefined,
              lastDigits: getLastDigits(editValue.account),
              connectionType: 'manual' as const,
            };
          }
          if (editValue.type === 'platform') {
            return {
              id: editValue.id,
              displayName: editValue.type as any,
              type: editValue.accountType,
              balance: undefined,
              lastDigits: getLastDigits(editValue.lastDigits),
              connectionType: 'platform' as const,
            };
          }
          if (editValue.type === 'plaid') {
            return {
              id: editValue.id || '',
              displayName: editValue.accountType as any,
              type: editValue.accountType,
              balance: undefined,
              lastDigits: 'last digits',
              connectionType: 'plaid' as const,
            };
          }

          return {
            id: editValue.id || '',
            displayName: editValue.accountType,
            type: editValue.accountType as any,
            lastDigits: getLastDigits(editValue.lastDigits),
            balance: 0,
            connectionType: 'platform' as const,
          };
        }),
        O.alt(() =>
          pipe(
            RD.toOption(achRD),
            O.map((editValue) => ({
              id: editValue.id,
              displayName: editValue.type,
              type: editValue.type as any,
              lastDigits: getLastDigits(editValue.lastDigits),
              // portfolioId: '',
              balance: editValue.balance,
              connectionType: editValue.connectionType,
            })),
          ),
        ),
        O.map((ach) => ({
          ...ach,
          balance:
            ach.balance ||
            accountsPlain.find((plainAccount) => plainAccount.lastDigits.endsWith(ach.lastDigits))?.balance,
        })),
      ),
    [editACH, achRD, accountsPlain],
  );
};

export const useActiveAch = () => {
  const activeACHRelationship = useActiveACHRelationship();

  return useMemo(
    () =>
      F.pipe(
        activeACHRelationship,
        O.map((ach) => ({
          destinationAddress: ach.lastDigits,
          destinationType: ach.type,
          balance: ach.balance,
          id: ach.id,
        })),
      ),
    [activeACHRelationship],
  );
};

export const useDestination = () => {
  const activeACH = useActiveAch();

  return useMemo(
    () =>
      F.pipe(
        activeACH,
        O.getOrElse(() => ({
          destinationAddress: '',
          destinationType: '' as string | undefined,
        })),
      ),
    [activeACH],
  );
};
