import { HoldingDTO, HoldingEntity } from '../Holding.entity.types';

export const mapPendingStateDtoToDomain = (dto: HoldingDTO['pending_state']): HoldingEntity['pendingState'] => {
  switch (dto) {
    case 'ADDITION':
      return 'addition';
    case 'REMOVAL':
      return 'removal';
    case 'SELLING':
      return 'selling';
    case 'BUYING':
      return 'buying';
  }
};
