import { PropsWithChildren } from 'react';

import * as Styled from './ChangeAccountAssert.styled';

const MSG = 'If you change your connected bank account, any deposits in progress will be cancelled immediately.';

export const ChangeAccountAssert: React.FC<PropsWithChildren> = (props) => {
  return (
    <Styled.Container>
      <div>{props.children || MSG}</div>
    </Styled.Container>
  );
};
