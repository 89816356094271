import { NotificationType } from '@models/application/notifications';

import { addNotification } from '@utils/notifications';

export const onInfoNotification = (message: string, closable = false) => {
  addNotification({
    type: NotificationType.Info,
    payload: {
      config: { closable },
      content: { text: message },
    },
  });
};
