import { useSelector } from 'react-redux';

import { holdingsSelector } from '../../selectors';
import { holdingsDataEq } from '../../helpers';

export const usePortfolioHoldings = () => {
  const holdings = useSelector(holdingsSelector, holdingsDataEq.equals);

  return [holdings] as const;
};
