import { ConfirmationTypes } from './types';
import * as builder from '@store/builder';

export const confirmationRequest = builder.buildRequestAction(ConfirmationTypes.REQUEST);
export const confirmationStarted = builder.buildStartedAction(ConfirmationTypes.STARTED);
export const confirmationSucceeded = builder.buildSimpleSucceededAction(ConfirmationTypes.SUCCEEDED);
export const confirmationFailed = builder.buildFailedAction(ConfirmationTypes.FAILED);
export const resetConfirmationScreenError = () => ({
  type: ConfirmationTypes.RESET_ERROR,
});

export type ConfirmationSucceededAction = ReturnType<typeof confirmationSucceeded>;
