import styled from 'styled-components';
import { gaps } from '~/ui/kit/constants/sizes';
import { SuccessIcon } from '~/ui/kit/organisms/Result/images/Success.icon';
import { textEllipsis } from '~/ui/kit/constants/textEllipsis';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${gaps.xxs}px;
`;

export const NameContainer = styled.span`
  display: flex;
  align-items: center;
  gap: ${gaps.xxs}px;
  overflow: hidden;
  color: var(--eko-primary-color);
`;

export const Name = styled.span`
  font-weight: bold;
  ${textEllipsis};
`;

export const Balance = styled.span`
  color: var(--eko-third-color);
  font-weight: normal;
`;

export const SelectedIcon = styled(SuccessIcon)`
  height: 16px;
  min-width: 16px;
  max-width: 16px;
`;
