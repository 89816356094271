import styled from 'styled-components';
import { media } from '~/ui/utils/media';

export const ConfirmationEmoji = styled.span`
  font-size: 20px;

  ${media.md`
    font-size: 24px;
  `}
`;
