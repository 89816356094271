import * as types from './types';
import * as builder from '@store/builder';

import { Profile } from '@models/profile';

export const fetchProfile = builder.buildRequestAction(types.FETCH_PROFILE);
export const fetchProfileStarted = builder.buildStartedAction(types.FETCH_PROFILE_STARTED);
export const fetchProfileSucceeded = builder.buildSucceededAction<Profile>(
  types.FETCH_PROFILE_SUCCEEDED,
  'profileInfo',
);
export const fetchProfileFailed = builder.buildFailedAction(types.FETCH_PROFILE_FAILED);
