import { useCallback, useState } from 'react';
import { constVoid } from 'fp-ts/function';
import { useDispatch } from 'react-redux';
import * as RD from '@devexperts/remote-data-ts';

import { ApiError } from '@models/ApiError';

import { convertToSelfDirected } from '../../api';
import { updatePortfolioItem } from '../../list';
import { PortfolioListItem } from '../../portfolio.types';

export const useConvertToSelfDirected = (onSuccess: (newPortfolioItem: PortfolioListItem) => void = constVoid) => {
  const dispatch = useDispatch();
  const [state, setState] = useState<RD.RemoteData<ApiError, PortfolioListItem>>(RD.initial);

  const handleSuccess: typeof onSuccess = useCallback(
    (newPortfolioItem) => {
      dispatch(updatePortfolioItem(newPortfolioItem));
      onSuccess(newPortfolioItem);
    },
    [onSuccess, dispatch],
  );

  const convert = useCallback(
    (portfolioId: string) => {
      setState(RD.pending);

      convertToSelfDirected(portfolioId, handleSuccess).then(setState);
    },
    [handleSuccess],
  );

  const reset = useCallback(() => {
    setState(RD.initial);
  }, []);

  return [state, convert, reset] as const;
};
