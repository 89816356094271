import { Ord } from 'fp-ts/Ord';
import isAfter from 'date-fns/isAfter';
import { PendingItem } from '~/entities/transfers/pendging';

export const pendingItemsCreationOrd: Ord<PendingItem> = {
  compare: (a, b) => {
    if (!a.createdAt && !b.createdAt) {
      return 0;
    }

    if (!a.createdAt) {
      return 1;
    }
    if (!b.createdAt) {
      return -1;
    }

    return isAfter(new Date(a.createdAt), new Date(b.createdAt)) ? 1 : -1;
  },
  equals: (a, b) => a.amount === b.amount && a.id === b.id,
};
