import * as RD from '@devexperts/remote-data-ts';
import { call, put, takeLatest } from 'redux-saga/effects';
import { Action } from 'redux';

import { ACHRelationship } from '@models/transfers';
import { mapApiError } from '@models/ApiError';
import * as builder from '@store/builder';
import { brokerAPI } from '~/api';

import { ACHAccountsValue } from '../types';

const initialState: ACHAccountsValue = RD.initial;

const action = builder.getModuleAction('ACH_ACCOUNTS');

const SET = action('SET');

const FETCH = action('FETCH');

type SetACHAccountsAction = Action<typeof SET> & {
  payload: typeof initialState;
};

export const ACHAccounts = (state = initialState, action: SetACHAccountsAction) => {
  switch (action.type) {
    case SET: {
      return action.payload;
    }
    default:
      return state;
  }
};

export function* loadACHAccounts(
  action: Action<typeof FETCH> & {
    payload: {
      portfolioId: string;
    };
  },
) {
  yield put(setACHAccounts(RD.pending));
  try {
    const accounts: ACHRelationship[] = yield call(() => brokerAPI.fetchAccounts(action.payload.portfolioId));

    yield put(setACHAccounts(accounts ? RD.success(accounts) : RD.success([])));
  } catch (e) {
    yield put(setACHAccounts(RD.failure(mapApiError(e))));
  }
}

export function* watchACHAccountsSaga() {
  yield takeLatest(FETCH, loadACHAccounts);
}

export const loadACHAccountsAction = (portfolioId: string) => ({
  type: FETCH,
  payload: {
    portfolioId,
  },
});

const setACHAccounts = (payload: ACHAccountsValue) => ({
  type: SET,
  payload: payload,
});
