import * as RD from '@devexperts/remote-data-ts';
import { ApiError } from '@models/ApiError';
import * as A from 'fp-ts/Array';
import { Eq } from 'fp-ts/Eq';

import { PortfolioListItem, PortfolioTypeValue } from '../portfolio.types';

const comparePortfolioListItems = (a: PortfolioListItem, b: PortfolioListItem) =>
  a.id === b.id &&
  a.portfolioType === b.portfolioType &&
  a.name === b.name &&
  a.cash === b.cash &&
  a.equity === b.equity &&
  a.firstDepositStatus === b.firstDepositStatus &&
  a.reservedCash === b.reservedCash &&
  a.riskLevel === b.riskLevel;

export const portfolioItemEq = RD.getEq<ApiError, PortfolioListItem>(
  {
    equals: (e1, e2) => e1.code === e2.code,
  },
  {
    equals: comparePortfolioListItems,
  },
);

export const portfolioListEq = RD.getEq<ApiError, PortfolioListItem[]>(
  {
    equals: (e1, e2) => e1.code === e2.code,
  },
  {
    equals: A.getEq({ equals: comparePortfolioListItems }).equals,
  },
);

export const portfolioItemEqById = RD.getEq<ApiError, PortfolioListItem>(
  {
    equals: (e1, e2) => e1.code === e2.code,
  },
  {
    equals: (a, b) => a.id === b.id,
  },
);

export const portfolioTypeEq: Eq<PortfolioTypeValue> = {
  equals: (a, b) => a === b,
};
