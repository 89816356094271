import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as RD from '@devexperts/remote-data-ts';
import { pipe } from 'fp-ts/function';

import { CREATE_PORTFOLIO } from '@routes/names';
import { modifyProfileSettingAction } from '@store/settings';
import { checkDeviceSize, useAppMediaContext } from '~/contexts/AppMedia';
import { setActivePortfolio, activePortfolioSelector, PortfolioListPlain } from '~/entities/protfolio';

import * as S from './portfolioSelector.feature.styled';
import { usePortfolioList } from '~/entities/protfolio/list';

type Props = {
  className?: string;
  leftPosition: number;
};

export const PortfolioSelector: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const activeId = useSelector(activePortfolioSelector);
  const { deviceSize } = useAppMediaContext();
  const isMobile = !checkDeviceSize.moreOrEqual.md(deviceSize);
  const portfolioList = usePortfolioList();

  const portfolios = useMemo(
    () =>
      pipe(
        portfolioList,
        RD.getOrElse((): PortfolioListPlain => []),
      ),
    [portfolioList],
  );

  const mappedOptions = useMemo(
    () =>
      portfolios.map((item) => ({
        label: <S.Item {...item} isSelected={activeId === item.id} />,
        value: item.id,
        stringName: item.name,
      })),
    [portfolios, activeId],
  );

  const onSelect = useCallback(
    (val: { value: string } | string) => {
      const targetId = typeof val === 'string' ? val : val.value;

      dispatch(setActivePortfolio(targetId));
      dispatch(modifyProfileSettingAction({ lastActivePortfolioId: targetId }));
    },
    [dispatch],
  );

  return (
    <>
      <S.DropdownStyles $isMobile={isMobile} $leftPosition={props.leftPosition} />
      <S.Container
        loading={RD.isPending(portfolioList)}
        options={mappedOptions}
        popupClassName={S.DROPDOWN_CLASSNAME}
        optionLabelProp="stringName"
        onSelect={onSelect}
        value={activeId}
        className={props.className}
        before={<S.Before>Switch portfolio</S.Before>}
        after={
          <S.After link={CREATE_PORTFOLIO}>
            <S.AddIcon />
            Add portfolio
          </S.After>
        }
      />
    </>
  );
};
